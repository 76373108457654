import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import MainNavBar from "../components/MainNavbar";
import { withRouter } from "react-router-dom";
import MuiPhoneNumber from "material-ui-phone-number";
import Auth from "@aws-amplify/auth";
import API, { graphqlOperation } from "@aws-amplify/api";
import CircularProgress from "@mui/material/CircularProgress";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { trackRegistration, trackLogin } from "../modules/Tracking";
import {
  userByCompany,
  clientByUserId,
  clientPackageByUser,
  getUser,
} from "../graphql/queries";
import {
  createUser,
  updateUser,
  updateClient,
  updateClientPackage,
  createClient,
} from "../graphql/mutations";
import { createClientRecord } from "../modules/BookingService";
import { v4 } from "uuid";
import { isGuestOnlyCheckout } from "../user/checkoutOption";
import { TriggerManager } from "../modules/TriggerManager";

const text_field_style = { width: "100%", maxWidth: "300px" };
const phone_field_style = {
  width: "100%",
  maxWidth: "300px",
  "& svg": { height: "1em" },
};
function SignUp(props) {
  const [guestMode, setGuestMode] = useState(
    isGuestOnlyCheckout(props.publicsitesettings)
      ? true
      : props.location?.state?.guestMode
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [pasw, setPasw] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showConfirmationSection, setShowConfirmationSection] = useState(false);
  const [confirmationCode, setConfirmationCode] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [error, setError] = useState();
  const [signupClicked, setSignupClicked] = useState(false);
  const [codeIsResent, setCodeIsResent] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);

  const smallerScreen = useMediaQuery("(min-width:600px)");

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  function formNotValid() {
    if (!validEmail()) {
      setError(true);
      setErrorMsg("Please provide a valid email address");
      return true;
    }
    if (!firstName) {
      setError(true);
      setErrorMsg("Please provide first name");
      return true;
    } else if (!lastName) {
      setError(true);
      setErrorMsg("Please provide last name");
      return true;
    } else if (!phoneNumber) {
      setError(true);
      setErrorMsg("Please provide a valid phone number");
      return true;
    }
    return false;
  }

  function validEmail() {
    // var mailformat =
    //   /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    var re =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
    if (email?.match(re)) {
      return true;
    } else {
      return false;
    }
  }

  async function checkAndhandleUnconfirmedAccount(email) {
    try {
      const { data: userDetails } = await API.get(
        "changeuserrole",
        "/getuser",
        {
          queryStringParameters: {
            username: email,
          },
        }
      );
      //FORCE_CHANGE_PASSWORD,RESET_REQUIRED,UNCONFIRMED
      if (userDetails?.UserStatus === "UNCONFIRMED") {
        // get user from db using userDetails.Username
        const {
          data: { getUser: mbUserInfo },
        } = await API.graphql(
          graphqlOperation(getUser, {
            id: userDetails.Username,
          })
        );
        if (!(mbUserInfo?.id === userDetails.Username)) {
          // if user does not exist in the db, delete cognito entry
          await API.post("changeuserrole", "/deleteuser", {
            body: {
              username: userDetails.Username,
            },
          });
          await new Promise((resolve) => setTimeout(resolve, 1000)); // to give cognito some time to clear the record
        }
      }
    } catch (e) {
      console.log(e);
    }
  }
  async function createAccount() {
    try {
      setSignupClicked(true);
      if (formNotValid()) return;
      await checkAndhandleUnconfirmedAccount(email);
      setErrorMsg();
      setError();
      const cleanedPhonNumber = phoneNumber.match(/\d+/g).join("");
      console.log(phoneNumber, cleanedPhonNumber);

      const { codeDeliveryDetails } = await Auth.signUp({
        username: email,
        password: pasw,
        attributes: {
          email,
          phone_number: `+${cleanedPhonNumber}`,
          given_name: firstName,
          family_name: lastName,
        },
      });
      if (codeDeliveryDetails) {
        setShowConfirmationSection(true);
        setSignupClicked(false);
      }
    } catch (error) {
      console.log("Signup error", error);
      setErrorMsg(error.message);
      setError(error);
    }
  }

  async function resendCode() {
    Auth.resendSignUp(email)
      .then(() => {
        setCodeIsResent(true);
      })
      .catch((err) => {
        setError(err);
        if (err.message) setErrorMsg(err.message);
      });
  }

  async function confirmSignup() {
    setSignupClicked(true);

    try {
      const confirmation = await Auth.confirmSignUp(email, confirmationCode);
      // TODO: what to do if signup fails?
      await addUserRole(email, "Client", "ADD");
      await Auth.signOut();
      await Auth.signIn(email, pasw);
      // Get new Authenticated user - newLoggedinuser should be the same as 'user' above
      const newloggedinuser = await Auth.currentAuthenticatedUser({
        bypassCache: true,
      });
      // object loginStats
      const loginInfo = {
        lastLoginDate: new Date(),
        loginCount: 1,
      };
      const LoginInfo = JSON.stringify(loginInfo);
      // create MB User object for the newly signedup user
      const newMBUserInput = {
        username: newloggedinuser.username,
        id: newloggedinuser.signInUserSession.idToken.payload.sub,
        emailaddress: newloggedinuser.signInUserSession.idToken.payload.email,
        firstname: newloggedinuser.signInUserSession.idToken.payload.given_name,
        lastname: newloggedinuser.signInUserSession.idToken.payload.family_name,
        registered: true,
        active: true,
        role: "CLIENT",
        companyId: props.company.id,
        userCompanyId: props.company.id,
        mobilephone:
          newloggedinuser.signInUserSession.idToken.payload.phone_number,
        LoginInfo,
        phonepref: "MOBILE",
        contactconsent: true,
        contactconsentdatetime: new Date().toISOString(),
      };
      const newMBUser = await API.graphql(
        graphqlOperation(createUser, { input: newMBUserInput })
      );

      trackRegistration(
        newMBUser && newMBUser.data && newMBUser.data.createUser
      );
      trackLogin(newMBUser && newMBUser.data && newMBUser.data.createUser);
      // handle situation when previos MB User was created in DB by Admin and this MB User
      // did not have to cognito account

      const usersByCompanyResult = await API.graphql(
        graphqlOperation(userByCompany, {
          companyId: props.company.id,
          roleEmailaddress: {
            beginsWith: {
              role: "CLIENT",
              emailaddress:
                newloggedinuser.signInUserSession.idToken.payload.email,
            },
          },
          filter: {
            and: [
              {
                emailaddress: {
                  eq: newloggedinuser.signInUserSession.idToken.payload.email,
                },
              },
              { registered: { eq: false } },
              { deleted: { ne: true } },
            ],
          },
          limit: 1000,
        })
      );

      if (
        usersByCompanyResult &&
        usersByCompanyResult.data.userByCompany.items.length > 0
      ) {
        // if a previously created user is found and has purchased packages, adjust the
        // index of client packages to point to the new MB User that has cognito profile
        const userData = usersByCompanyResult.data.userByCompany.items[0];
        const clientPackagesResult = await API.graphql(
          graphqlOperation(clientPackageByUser, {
            userId: userData.id,
          })
        );
        if (
          clientPackagesResult &&
          clientPackagesResult.data.clientPackageByUser.items.length > 0
        ) {
          clientPackagesResult.data.clientPackageByUser.items.map(
            async (item) => {
              const updateClientPackageResult = await API.graphql(
                graphqlOperation(updateClientPackage, {
                  input: {
                    id: item.id,
                    userId:
                      newloggedinuser.signInUserSession.idToken.payload.sub,
                    clientPackageUserId:
                      newloggedinuser.signInUserSession.idToken.payload.sub,
                  },
                })
              );
            }
          );
        }
        // Delete the old MB User profile that did not have the cognito profile
        const deleteOldUserProfile = await API.graphql(
          graphqlOperation(updateUser, {
            input: { id: userData.id, deleted: true },
          })
        );

        const clientProfile = await API.graphql(
          graphqlOperation(clientByUserId, {
            userId: userData.id,
          })
        );
        const clientId = clientProfile.data.clientByUserId.items[0].id;
        // update existing client to point to the new MB User
        const updateClientResult = await API.graphql(
          graphqlOperation(updateClient, {
            input: {
              id: clientId,
              userId: newloggedinuser.signInUserSession.idToken.payload.sub,
              clientUserId:
                newloggedinuser.signInUserSession.idToken.payload.sub,
            },
          })
        );
        props.setUser(newloggedinuser);
        props.setClient({
          isNewClient: false,
          client: updateClientResult.data.updateClient,
        });
      } else {
        const clientRecord = await createClientRecord(
          newMBUser.data.createUser,
          props.company
        );
        /* Zapier Trigger - New Client (new-client-zapier)*/
        try {
          const date = new Date();
          const dateString = date.toLocaleString("en-US");
          let clientData = {
            email: newMBUserInput.emailaddress,
            userType: "Client",
            phoneNumber: newMBUserInput.mobilephone,
            createdAt: dateString,
            firstName: newMBUserInput.firstname,
            lastName: newMBUserInput.lastname,
          };
          let hookName = "new-client-zapier";
          await TriggerManager(clientData, newMBUserInput.companyId, hookName);
        } catch (e) {
          console.log("ERROR: unable to perform triggerManager", e);
        }
        props.setUser(newloggedinuser);
        props.setClient({
          isNewClient: true,
          client: clientRecord,
        });
      }

      props.history.push(
        props.location.state?.fromPath ? props.location.state.fromPath : "/"
      );
    } catch (error) {
      if (error.code === "CodeMismatchException") setErrorMsg("Invalid code");
      console.log("Signup error", JSON.stringify(error));
      setSignupClicked(false);
    }
    setSignupClicked(false);
  }

  async function addUserRole(username, groupName, action) {
    const result = await API.post("changeuserrole", "/changeuserrole", {
      body: {
        username,
        groupName,
        action,
      },
    });
  }

  async function continueAsGuest() {
    try {
      const userByEmailResult = await API.graphql(
        graphqlOperation(userByCompany, {
          companyId: props.company.id,
          roleEmailaddress: {
            beginsWith: {
              role: "CLIENT",
              emailaddress: email,
            },
          },
          filter: { deleted: { ne: true } },

          limit: 1000,
        })
      );
      if (
        userByEmailResult &&
        userByEmailResult.data.userByCompany.items.length === 1
      ) {
        // save user
        props.setBookingUserInState(
          userByEmailResult.data.userByCompany.items[0]
        );
        props.history.push(props.location.state?.fromPath);
        return;
      }
    } catch (err) {
      console.log("error while reading user by email address", err);
    }
    try {
      const newumbser = await addUserToDb();
      if (newumbser) {
        const clientData = {
          userId: newumbser.id,
          companyId: props.company.id,
          clientUserId: newumbser.id,
          currency: props.company.currency ? props.company.currency : "CAD",
          clientCompanyId: props.company.id,
          accountbalance: 0.0,
        };
        await addClient(clientData);
        props.setBookingUserInState(newumbser);
        props.history.push(props.location.state?.fromPath);
      }
    } catch (err) {
      console.log(err);
    }
  }

  async function addUserToDb() {
    try {
      const uuidValue = v4();
      const cleanPhone = phoneNumber.match(/\d+/g).join("");
      let registerUserInput = {
        id: uuidValue,
        username: uuidValue,
        emailaddress: email,
        firstname: firstName,
        lastname: lastName,
        registered: false,
        active: true,
        role: "CLIENT",
        companyId: props.company.id,
        userCompanyId: props.company.id,
        mobilephone: `+${cleanPhone}`,
        phonepref: "MOBILE",
        contactconsent: true,
        contactconsentdatetime: new Date().toISOString(),
      };
      const newUser = await API.graphql(
        graphqlOperation(createUser, { input: registerUserInput })
      );
      return newUser.data.createUser;
      //return uuidValue;
    } catch (err) {
      console.log(err);
    }
  }

  async function addClient(input) {
    let newClient = await API.graphql(
      graphqlOperation(createClient, { input })
    );
    return newClient;
  }

  const disableCreateBtn = () => {
    let disable = true;

    if (
      firstName.trim().length !== 0 &&
      lastName.trim().length !== 0 &&
      email.trim().length !== 0 &&
      phoneNumber.trim().length > 3 &&
      pasw.trim().length !== 0
    ) {
      disable = false;
    }
    return disable;
  };

  const disableConfirmationBtn = () => {
    let disable = true;
    if (confirmationCode.trim().length !== 0) {
      disable = false;
    }

    return disable;
  };

  const createAccountOnKeyDown = (e) => {
    if (e.key === "Enter") {
      setSignupClicked(true);
      createAccount();
    }
  };

  const confirmSignupOnKeyDown = (e) => {
    if (e.key === "Enter") {
      setSignupClicked(true);
      confirmSignup();
    }
  };

  return (
    <div>
      <MainNavBar
        company={props.company}
        user={null}
        guestUser={true}
        signOutOp={props.signOutOp}
        currentPath={props.location.pathname}
        banner={false}
        publicsitesettings={props.publicsitesettings}
      />
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          gap: "1rem",
          marginTop: "115px",
          padding: "0 1rem",
        }}
      >
        <Typography variant="h3" sx={{ textAlign: "center" }}>
          {`Welcome to ${props.company.name}`}
        </Typography>
        {showConfirmationSection ? (
          <>
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.5rem",
                marginBottom: "1rem",
              }}
            >
              <Typography variant="body2">
                {`We have sent a confirmation code to your email address`}
              </Typography>
            </Box>

            <TextField
              sx={{
                ...text_field_style,
                maxWidth: !smallerScreen ? "unset" : "300px",
              }}
              InputProps={{ disableUnderline: true }}
              variant="outlined"
              size="small"
              id="userenteredinfo"
              label="Confirmation code"
              value={confirmationCode ? confirmationCode : ""}
              onChange={(e) => {
                if (error && errorMsg) {
                  setError(false);
                  setErrorMsg("");
                }
                setConfirmationCode(e.target.value);
              }}
              onKeyDown={confirmSignupOnKeyDown}
            />

            {errorMsg && (
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                <Typography variant="body2" style={{ color: "red" }}>
                  {errorMsg}
                </Typography>
              </Box>
            )}
            <Button
              variant="contained"
              sx={{
                ...text_field_style,
                maxWidth: !smallerScreen ? "unset" : "300px",
              }}
              onClick={confirmSignup}
              disabled={signupClicked || disableConfirmationBtn()}
            >
              {signupClicked ? (
                <CircularProgress size={12} sx={{ color: "#fff" }} />
              ) : (
                "Confirm code"
              )}
            </Button>
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.5rem",
                marginBottom: "1rem",
              }}
            >
              <Typography variant="body2">
                Lost your code?{" "}
                <Box
                  sx={{
                    display: "inline",
                    fontWeight: 700,
                    color: "primary.main",
                    cursor: "pointer",
                  }}
                  onClick={resendCode}
                >
                  Resend code
                </Box>
              </Typography>
            </Box>
            {codeIsResent && (
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                <Typography variant="body2">
                  We have sent you an email with the new code.
                </Typography>
              </Box>
            )}
          </>
        ) : (
          <>
            <Box
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "0.5rem",
                marginBottom: "1rem",
              }}
            >
              <Typography variant="body2">
                {guestMode
                  ? `Please provide contact information to continue as a guest`
                  : `Create a new account`}
              </Typography>
            </Box>
            {errorMsg && (
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                <Typography variant="body2" style={{ color: "red" }}>
                  {errorMsg}
                </Typography>
              </Box>
            )}
            <TextField
              sx={{
                ...text_field_style,
                maxWidth: !smallerScreen ? "unset" : "300px",
              }}
              InputProps={{ disableUnderline: true }}
              variant="outlined"
              size="small"
              id="firstName"
              label="First name"
              value={firstName}
              onChange={(e) => {
                if (error) {
                  setError(false);
                  setErrorMsg("");
                }
                setFirstName(e.target.value);

                //createAccountOnKeyDown(e);
              }}
              onKeyDown={createAccountOnKeyDown}
            />
            <TextField
              sx={{
                ...text_field_style,
                maxWidth: !smallerScreen ? "unset" : "300px",
              }}
              InputProps={{ disableUnderline: true }}
              variant="outlined"
              size="small"
              id="lastName"
              label="Last name"
              value={lastName}
              onChange={(e) => {
                if (error) {
                  setError(false);
                  setErrorMsg("");
                }
                setLastName(e.target.value);
              }}
              onKeyDown={createAccountOnKeyDown}
            />
            <TextField
              sx={{
                ...text_field_style,
                maxWidth: !smallerScreen ? "unset" : "300px",
              }}
              InputProps={{ disableUnderline: true }}
              variant="outlined"
              size="small"
              id="email"
              label="Email"
              value={email}
              onChange={(e) => {
                if (error) {
                  setError(false);
                  setErrorMsg("");
                }
                let ev = e.target.value;
                ev = ev.trim().toLowerCase();
                setEmail(ev);
              }}
              onKeyDown={createAccountOnKeyDown}
            />
            <MuiPhoneNumber
              sx={{
                ...phone_field_style,
                maxWidth: !smallerScreen ? "unset" : "300px",
              }}
              variant="outlined"
              size="small"
              InputProps={{ disableUnderline: true }}
              id="phoneNumber"
              label="Phone"
              defaultCountry={props.defaultCountry}
              value={phoneNumber}
              onChange={(val) => {
                if (error) {
                  setError(false);
                  setErrorMsg("");
                }
                setPhoneNumber(val);
              }}
              onKeyDown={createAccountOnKeyDown}
            />
            {!guestMode && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",

                  gap: "1rem",
                  width: "100%",
                }}
              >
                <TextField
                  sx={{
                    ...text_field_style,
                    alignSelf: "center",
                    maxWidth: !smallerScreen ? "unset" : "300px",
                  }}
                  type={showPassword ? "text" : "password"}
                  InputProps={{
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  variant="outlined"
                  size="small"
                  id="password"
                  label="Password"
                  value={pasw}
                  onChange={(e) => {
                    if (error) {
                      setError(false);
                      setErrorMsg("");
                    }
                    setPasw(e.target.value);
                    //createAccountOnKeyDown(e);
                  }}
                  onKeyDown={createAccountOnKeyDown}
                />
              </Box>
            )}
            {guestMode && (
              <Button
                id="guest-account"
                variant="contained"
                sx={{
                  ...text_field_style,
                  maxWidth: !smallerScreen ? "unset" : "300px",
                }}
                onClick={continueAsGuest}
              >
                Continue As Guest
              </Button>
            )}{" "}
            {!guestMode && (
              <Button
                id="create-account"
                variant="contained"
                sx={{
                  ...text_field_style,
                  maxWidth: !smallerScreen ? "unset" : "300px",
                }}
                disabled={signupClicked || disableCreateBtn()}
                onClick={createAccount}
              >
                {signupClicked ? (
                  <CircularProgress size={12} sx={{ color: "primary.main" }} />
                ) : (
                  "Create account"
                )}
              </Button>
            )}
            {!isGuestOnlyCheckout(props.publicsitesettings) && (
              <Box
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                <Typography variant="body2">
                  Have an account?{" "}
                  <Box
                    sx={{
                      display: "inline",
                      fontWeight: 700,
                      color: "primary.main",
                      cursor: "pointer",
                    }}
                    onClick={(event) =>
                      props.history.push("/login", {
                        fromPath: props.location.state?.fromPath
                          ? props.location.state?.fromPath
                          : "/",
                      })
                    }
                  >
                    Sign in
                  </Box>
                </Typography>
              </Box>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default withRouter(SignUp);
