/* Common user functions */

// imports for addUserToGroup
import { Logger } from "@aws-amplify/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import Cache from "@aws-amplify/cache";
import Auth from "@aws-amplify/auth";
import {
  serviceTypeByCompany,
  listSkills,
  getUser,
  userByCompany,
} from "../graphql/queries";

const logger = new Logger("UserCommon");

const USERROLE = {
  CLIENT: "CLIENT",
  PROVIDER: "PROVIDER",
  COMPANY_ADMIN: "COMPANY_ADMIN",
  MARKETBOX_ADMIN: "MARKETBOX_ADMIN",
  COMPANY_ADMIN_PROVIDER: "COMPANY_ADMIN_PROVIDER",
};

const fetchSkillsData = async () => {
  const user = Cache.getItem("user");
  const response = await API.graphql(
    graphqlOperation(listSkills, {
      filter: {
        and: [
          { active: { ne: false } },
          { deleted: { ne: true } },
          { companyId: { eq: user && user.company ? user.company.id : "" } },
        ],
      },
      limit: 50,
    })
  );
  const result = await response.data.listSkills.items;
  // store in Amplify cache
  Cache.setItem("skills", result);
};

const fetchServiceTypeData = async () => {
  const user = Cache.getItem("user");

  const filter = {
    and: [
      { active: { ne: false } },
      { deleted: { ne: true } },
      { isVisible: { ne: false } },
    ],
  };
  const response = await API.graphql(
    graphqlOperation(serviceTypeByCompany, {
      companyId: user.company.id,
      filter,
      limit: process.env.REACT_APP_LISTLIMIT,
    })
  );
  const result = await response.data.serviceTypeByCompany.items;
  Cache.setItem("servicetypes", result);
};

const getAuthUser = async () => {
  const user = await Auth.currentAuthenticatedUser();
  return user;
};

const getAuthUserEmail = async () => {
  const user = await Auth.currentAuthenticatedUser();
  const email = await user.attributes.email;
  return email;
};

async function handleSendEmail(
  subject,
  body,
  toAddresses,
  ccAddresses,
  bccAddresses,
  replyTo,
  companyName
) {
  const result = await API.post("sendtwilioemail", "/sendtwilioemail", {
    body: {
      subject,
      body,
      toAddresses,
      ccAddresses,
      bccAddresses,
      replyTo,
      companyName,
    },
  });
}

async function getCompanyAdmins(companyId) {
  const result = await API.graphql(
    graphqlOperation(userByCompany, {
      companyId,
      roleEmailaddress: {
        beginsWith: {
          role: "COMPANY_ADMIN",
        },
      },
      filter: {
        and: [
          { active: { ne: false } },
          { deleted: { ne: true } },
          { registered: { ne: false } },
        ],
      },
      limit: process.env.REACT_APP_LISTLIMIT,
    })
  );
  return result.data.userByCompany.items;
}

async function getUserFromCache() {
  let user = Cache.getItem("user");
  if (user) {
    if (user.emailaddress === process.env.REACT_APP_MB_GUEST_EMAIL) {
      Cache.removeItem("user");
      user = null;
    }
  }
  if (!user) {
    const authUser = await Auth.currentAuthenticatedUser({
      bypassCache: true,
    });
    if (!authUser) return;
    if (authUser?.attributes?.email === process.env.REACT_APP_MB_GUEST_EMAIL) {
      console.log("the non-guest user not logged in");
      return;
    }
    // console.log("user should not be guest", authUser.username);
    const userData = await API.graphql(
      graphqlOperation(getUser, {
        id: authUser.username,
      })
    );
    if (
      userData &&
      userData.data.getUser.emailaddress ===
        process.env.REACT_APP_MB_GUEST_EMAIL
    )
      return;
    else {
      user = userData.data.getUser;
      // console.log("Putting user ", authUser.attributes.email, " in cache.");
      Cache.setItem("user", user);
      return user;
    }
  }
  // console.log("Putting user from cache ", user.emailaddress);
  return user;
}

async function getUserRole() {
  const user = await getUserFromCache();
  // logger.debug(user);
  if (user && user.role) {
    if (user.role === USERROLE.CLIENT) return USERROLE.CLIENT;
    if (user.role === USERROLE.PROVIDER) return USERROLE.PROVIDER;
    if (user.role === USERROLE.COMPANY_ADMIN) return USERROLE.COMPANY_ADMIN;
    if (user.role === USERROLE.COMPANY_ADMIN_PROVIDER)
      return USERROLE.COMPANY_ADMIN_PROVIDER;
    if (user.role === USERROLE.MARKETBOX_ADMIN) return USERROLE.MARKETBOX_ADMIN;
  } else {
    logger.debug("*** WARNING: user.role null in UserCommon, getUserRole");
    return USERROLE.CLIENT;
  }
}

async function userHasAdminRole() {
  const user = await getUserFromCache();
  if (user && user.role)
    return (
      USERROLE.MARKETBOX_ADMIN === user.role ||
      USERROLE.COMPANY_ADMIN === user.role ||
      USERROLE.COMPANY_ADMIN_PROVIDER === user.role
    );
  else return false;
}
async function userHasClientRole() {
  const user = await getUserFromCache();
  if (user && user.role) return USERROLE.CLIENT === user.role;
  else return false;
}
export {
  fetchSkillsData,
  fetchServiceTypeData,
  getUserRole,
  getUserFromCache,
  USERROLE,
  getAuthUser,
  getAuthUserEmail,
  handleSendEmail,
  userHasAdminRole,
  userHasClientRole,
  getCompanyAdmins,
};
