/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserRegistration = /* GraphQL */ `
  mutation CreateUserRegistration($input: CreateUserRegistrationInput!) {
    createUserRegistration(input: $input) {
      id
      emailaddress
      registrationInfo
      createdAt
      updatedAt
    }
  }
`;
export const updateUserRegistration = /* GraphQL */ `
  mutation UpdateUserRegistration($input: UpdateUserRegistrationInput!) {
    updateUserRegistration(input: $input) {
      id
      emailaddress
      registrationInfo
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserRegistration = /* GraphQL */ `
  mutation DeleteUserRegistration($input: DeleteUserRegistrationInput!) {
    deleteUserRegistration(input: $input) {
      id
      emailaddress
      registrationInfo
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyPublicSite = /* GraphQL */ `
  mutation CreateCompanyPublicSite($input: CreateCompanyPublicSiteInput!) {
    createCompanyPublicSite(input: $input) {
      id
      companyId
      backgroundImageUrl
      heading1Text
      heading2Text
      textColor
      buttonColor
      StyleCustomization
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCompanyPublicSite = /* GraphQL */ `
  mutation UpdateCompanyPublicSite($input: UpdateCompanyPublicSiteInput!) {
    updateCompanyPublicSite(input: $input) {
      id
      companyId
      backgroundImageUrl
      heading1Text
      heading2Text
      textColor
      buttonColor
      StyleCustomization
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCompanyPublicSite = /* GraphQL */ `
  mutation DeleteCompanyPublicSite($input: DeleteCompanyPublicSiteInput!) {
    deleteCompanyPublicSite(input: $input) {
      id
      companyId
      backgroundImageUrl
      heading1Text
      heading2Text
      textColor
      buttonColor
      StyleCustomization
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      username
      emailaddress
      registered
      firstname
      lastname
      homephone
      workphone
      mobilephone
      phonepref
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      role
      companyId
      providerId
      active
      deleted
      contactconsent
      contactconsentdatetime
      LoginInfo
      SalesInfo
      PNInfo
      lc_name
      lc_emailaddress
      lc_ln
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      username
      emailaddress
      registered
      firstname
      lastname
      homephone
      workphone
      mobilephone
      phonepref
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      role
      companyId
      providerId
      active
      deleted
      contactconsent
      contactconsentdatetime
      LoginInfo
      SalesInfo
      PNInfo
      lc_name
      lc_emailaddress
      lc_ln
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      username
      emailaddress
      registered
      firstname
      lastname
      homephone
      workphone
      mobilephone
      phonepref
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      role
      companyId
      providerId
      active
      deleted
      contactconsent
      contactconsentdatetime
      LoginInfo
      SalesInfo
      PNInfo
      lc_name
      lc_emailaddress
      lc_ln
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const createUserRemoteAddress = /* GraphQL */ `
  mutation CreateUserRemoteAddress($input: CreateUserRemoteAddressInput!) {
    createUserRemoteAddress(input: $input) {
      id
      userId
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      active
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateUserRemoteAddress = /* GraphQL */ `
  mutation UpdateUserRemoteAddress($input: UpdateUserRemoteAddressInput!) {
    updateUserRemoteAddress(input: $input) {
      id
      userId
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      active
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteUserRemoteAddress = /* GraphQL */ `
  mutation DeleteUserRemoteAddress($input: DeleteUserRemoteAddressInput!) {
    deleteUserRemoteAddress(input: $input) {
      id
      userId
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      active
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createUserInvitation = /* GraphQL */ `
  mutation CreateUserInvitation($input: CreateUserInvitationInput!) {
    createUserInvitation(input: $input) {
      id
      emailaddress
      invitecode
      role
      active
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const updateUserInvitation = /* GraphQL */ `
  mutation UpdateUserInvitation($input: UpdateUserInvitationInput!) {
    updateUserInvitation(input: $input) {
      id
      emailaddress
      invitecode
      role
      active
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserInvitation = /* GraphQL */ `
  mutation DeleteUserInvitation($input: DeleteUserInvitationInput!) {
    deleteUserInvitation(input: $input) {
      id
      emailaddress
      invitecode
      role
      active
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany($input: CreateCompanyInput!) {
    createCompany(input: $input) {
      id
      name
      contactname
      emailaddress
      currency
      currencyBasedOnLocation
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      clientcanselectprovider
      active
      offersRemoteServices
      offersOnPremiseServices
      offersVirtualServices
      providerMustAcceptAppt
      useAnonymousPermalink
      multipleServices
      multipleQty
      ApptAcceptanceFlowConfig
      collectpayment
      subdomain
      tagline
      logoUrl
      billingMode
      iframeURL
      primaryColor
      addServiceFee
      serviceFeeType
      serviceFeeAmount
      taxrate
      travelTime
      bookingIntervalMinutes
      countrycode3166alpha2
      publishableStripeKey
      displayOnlyLogo
      sendratingTipMsg
      ratingTipTextMsg
      offersPackagesOnly
      forceRepeatingPackages
      offersForeverAppt
      singleApptOnly
      virtualMeetingConfig
      DashboardInfo
      BccLists
      maskeddomain
      replyemailaddress
      homepage
      clientnotesheading
      hideRatingsFromClients
      subscriptionLevel
      cancelPolicyMinsBefore
      bookingIncrement
      providerCanCancelAppt
      noProviderMsg
      displayProviderRatios
      PaymentSettings
      stripeConnectEnabled
      stripeAccount
      setupCompleted
      clientCanReschedule
      providerCanReschedule
      ProvAgnoFlowConfig
      SuggestionConfig
      createdAt
      updatedAt
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany($input: UpdateCompanyInput!) {
    updateCompany(input: $input) {
      id
      name
      contactname
      emailaddress
      currency
      currencyBasedOnLocation
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      clientcanselectprovider
      active
      offersRemoteServices
      offersOnPremiseServices
      offersVirtualServices
      providerMustAcceptAppt
      useAnonymousPermalink
      multipleServices
      multipleQty
      ApptAcceptanceFlowConfig
      collectpayment
      subdomain
      tagline
      logoUrl
      billingMode
      iframeURL
      primaryColor
      addServiceFee
      serviceFeeType
      serviceFeeAmount
      taxrate
      travelTime
      bookingIntervalMinutes
      countrycode3166alpha2
      publishableStripeKey
      displayOnlyLogo
      sendratingTipMsg
      ratingTipTextMsg
      offersPackagesOnly
      forceRepeatingPackages
      offersForeverAppt
      singleApptOnly
      virtualMeetingConfig
      DashboardInfo
      BccLists
      maskeddomain
      replyemailaddress
      homepage
      clientnotesheading
      hideRatingsFromClients
      subscriptionLevel
      cancelPolicyMinsBefore
      bookingIncrement
      providerCanCancelAppt
      noProviderMsg
      displayProviderRatios
      PaymentSettings
      stripeConnectEnabled
      stripeAccount
      setupCompleted
      clientCanReschedule
      providerCanReschedule
      ProvAgnoFlowConfig
      SuggestionConfig
      createdAt
      updatedAt
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany($input: DeleteCompanyInput!) {
    deleteCompany(input: $input) {
      id
      name
      contactname
      emailaddress
      currency
      currencyBasedOnLocation
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      clientcanselectprovider
      active
      offersRemoteServices
      offersOnPremiseServices
      offersVirtualServices
      providerMustAcceptAppt
      useAnonymousPermalink
      multipleServices
      multipleQty
      ApptAcceptanceFlowConfig
      collectpayment
      subdomain
      tagline
      logoUrl
      billingMode
      iframeURL
      primaryColor
      addServiceFee
      serviceFeeType
      serviceFeeAmount
      taxrate
      travelTime
      bookingIntervalMinutes
      countrycode3166alpha2
      publishableStripeKey
      displayOnlyLogo
      sendratingTipMsg
      ratingTipTextMsg
      offersPackagesOnly
      forceRepeatingPackages
      offersForeverAppt
      singleApptOnly
      virtualMeetingConfig
      DashboardInfo
      BccLists
      maskeddomain
      replyemailaddress
      homepage
      clientnotesheading
      hideRatingsFromClients
      subscriptionLevel
      cancelPolicyMinsBefore
      bookingIncrement
      providerCanCancelAppt
      noProviderMsg
      displayProviderRatios
      PaymentSettings
      stripeConnectEnabled
      stripeAccount
      setupCompleted
      clientCanReschedule
      providerCanReschedule
      ProvAgnoFlowConfig
      SuggestionConfig
      createdAt
      updatedAt
    }
  }
`;
export const createPendingCompany = /* GraphQL */ `
  mutation CreatePendingCompany($input: CreatePendingCompanyInput!) {
    createPendingCompany(input: $input) {
      id
      name
      contactname
      emailaddress
      subdomain
      publishableStripeKey
      subscriptionLevel
      estimatedNumOfProviders
      status
      PaymentSettings
      createdAt
      updatedAt
    }
  }
`;
export const updatePendingCompany = /* GraphQL */ `
  mutation UpdatePendingCompany($input: UpdatePendingCompanyInput!) {
    updatePendingCompany(input: $input) {
      id
      name
      contactname
      emailaddress
      subdomain
      publishableStripeKey
      subscriptionLevel
      estimatedNumOfProviders
      status
      PaymentSettings
      createdAt
      updatedAt
    }
  }
`;
export const deletePendingCompany = /* GraphQL */ `
  mutation DeletePendingCompany($input: DeletePendingCompanyInput!) {
    deletePendingCompany(input: $input) {
      id
      name
      contactname
      emailaddress
      subdomain
      publishableStripeKey
      subscriptionLevel
      estimatedNumOfProviders
      status
      PaymentSettings
      createdAt
      updatedAt
    }
  }
`;
export const createProvider = /* GraphQL */ `
  mutation CreateProvider($input: CreateProviderInput!) {
    createProvider(input: $input) {
      id
      firstname
      lc_fn
      lastname
      lc_ln
      emailaddress
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      timezone
      bio
      pictures3key
      maxtraveltype
      companyId
      numberofschedules
      offersMobileServices
      active
      deleted
      ratingstarsavg
      numberofratings
      offersVirtualServices
      virtualMeetingUserId
      vmlink
      permalink
      AcceptanceRatios
      userId
      maxTravelTime
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      locations {
        items {
          id
          providerId
          companyId
          locationpath
          name
          desc
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          maxtraveltype
          traveldistance
          traveldistanceunit
          travelregions
          active
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
      servicetypes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      skills {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateProvider = /* GraphQL */ `
  mutation UpdateProvider($input: UpdateProviderInput!) {
    updateProvider(input: $input) {
      id
      firstname
      lc_fn
      lastname
      lc_ln
      emailaddress
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      timezone
      bio
      pictures3key
      maxtraveltype
      companyId
      numberofschedules
      offersMobileServices
      active
      deleted
      ratingstarsavg
      numberofratings
      offersVirtualServices
      virtualMeetingUserId
      vmlink
      permalink
      AcceptanceRatios
      userId
      maxTravelTime
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      locations {
        items {
          id
          providerId
          companyId
          locationpath
          name
          desc
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          maxtraveltype
          traveldistance
          traveldistanceunit
          travelregions
          active
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
      servicetypes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      skills {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteProvider = /* GraphQL */ `
  mutation DeleteProvider($input: DeleteProviderInput!) {
    deleteProvider(input: $input) {
      id
      firstname
      lc_fn
      lastname
      lc_ln
      emailaddress
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      timezone
      bio
      pictures3key
      maxtraveltype
      companyId
      numberofschedules
      offersMobileServices
      active
      deleted
      ratingstarsavg
      numberofratings
      offersVirtualServices
      virtualMeetingUserId
      vmlink
      permalink
      AcceptanceRatios
      userId
      maxTravelTime
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      locations {
        items {
          id
          providerId
          companyId
          locationpath
          name
          desc
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          maxtraveltype
          traveldistance
          traveldistanceunit
          travelregions
          active
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
      servicetypes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      skills {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createProviderLocation = /* GraphQL */ `
  mutation CreateProviderLocation($input: CreateProviderLocationInput!) {
    createProviderLocation(input: $input) {
      id
      providerId
      companyId
      locationpath
      name
      desc
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      timezone
      maxtraveltype
      traveldistance
      traveldistanceunit
      travelregions
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const updateProviderLocation = /* GraphQL */ `
  mutation UpdateProviderLocation($input: UpdateProviderLocationInput!) {
    updateProviderLocation(input: $input) {
      id
      providerId
      companyId
      locationpath
      name
      desc
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      timezone
      maxtraveltype
      traveldistance
      traveldistanceunit
      travelregions
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const deleteProviderLocation = /* GraphQL */ `
  mutation DeleteProviderLocation($input: DeleteProviderLocationInput!) {
    deleteProviderLocation(input: $input) {
      id
      providerId
      companyId
      locationpath
      name
      desc
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      timezone
      maxtraveltype
      traveldistance
      traveldistanceunit
      travelregions
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const createProviderPhotos = /* GraphQL */ `
  mutation CreateProviderPhotos($input: CreateProviderPhotosInput!) {
    createProviderPhotos(input: $input) {
      id
      providerId
      name
      desc
      s3key
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const updateProviderPhotos = /* GraphQL */ `
  mutation UpdateProviderPhotos($input: UpdateProviderPhotosInput!) {
    updateProviderPhotos(input: $input) {
      id
      providerId
      name
      desc
      s3key
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const deleteProviderPhotos = /* GraphQL */ `
  mutation DeleteProviderPhotos($input: DeleteProviderPhotosInput!) {
    deleteProviderPhotos(input: $input) {
      id
      providerId
      name
      desc
      s3key
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const createProviderRatings = /* GraphQL */ `
  mutation CreateProviderRatings($input: CreateProviderRatingsInput!) {
    createProviderRatings(input: $input) {
      id
      providerId
      companyId
      ratingstars
      ratingtext
      ratinguserId
      active
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const updateProviderRatings = /* GraphQL */ `
  mutation UpdateProviderRatings($input: UpdateProviderRatingsInput!) {
    updateProviderRatings(input: $input) {
      id
      providerId
      companyId
      ratingstars
      ratingtext
      ratinguserId
      active
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const deleteProviderRatings = /* GraphQL */ `
  mutation DeleteProviderRatings($input: DeleteProviderRatingsInput!) {
    deleteProviderRatings(input: $input) {
      id
      providerId
      companyId
      ratingstars
      ratingtext
      ratinguserId
      active
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const createProviderServiceType = /* GraphQL */ `
  mutation CreateProviderServiceType($input: CreateProviderServiceTypeInput!) {
    createProviderServiceType(input: $input) {
      id
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
    }
  }
`;
export const updateProviderServiceType = /* GraphQL */ `
  mutation UpdateProviderServiceType($input: UpdateProviderServiceTypeInput!) {
    updateProviderServiceType(input: $input) {
      id
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
    }
  }
`;
export const deleteProviderServiceType = /* GraphQL */ `
  mutation DeleteProviderServiceType($input: DeleteProviderServiceTypeInput!) {
    deleteProviderServiceType(input: $input) {
      id
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
    }
  }
`;
export const createProviderSkill = /* GraphQL */ `
  mutation CreateProviderSkill($input: CreateProviderSkillInput!) {
    createProviderSkill(input: $input) {
      id
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      skill {
        id
        name
        importance
        companyId
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
      }
    }
  }
`;
export const updateProviderSkill = /* GraphQL */ `
  mutation UpdateProviderSkill($input: UpdateProviderSkillInput!) {
    updateProviderSkill(input: $input) {
      id
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      skill {
        id
        name
        importance
        companyId
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
      }
    }
  }
`;
export const deleteProviderSkill = /* GraphQL */ `
  mutation DeleteProviderSkill($input: DeleteProviderSkillInput!) {
    deleteProviderSkill(input: $input) {
      id
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      skill {
        id
        name
        importance
        companyId
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
      }
    }
  }
`;
export const createServiceType = /* GraphQL */ `
  mutation CreateServiceType($input: CreateServiceTypeInput!) {
    createServiceType(input: $input) {
      id
      name
      desc
      categoryId
      categoryName
      minutes
      price
      active
      deleted
      companyId
      s3key
      imagedesc
      offeredremote
      offeredonpremise
      isBundledService
      isVisible
      includedServices
      behavior
      ordinal
      taxexempt
      TaxOverride
      locations
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      category {
        id
        name
        desc
        companyId
        deleted
        ordinal
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      packages {
        items {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateServiceType = /* GraphQL */ `
  mutation UpdateServiceType($input: UpdateServiceTypeInput!) {
    updateServiceType(input: $input) {
      id
      name
      desc
      categoryId
      categoryName
      minutes
      price
      active
      deleted
      companyId
      s3key
      imagedesc
      offeredremote
      offeredonpremise
      isBundledService
      isVisible
      includedServices
      behavior
      ordinal
      taxexempt
      TaxOverride
      locations
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      category {
        id
        name
        desc
        companyId
        deleted
        ordinal
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      packages {
        items {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteServiceType = /* GraphQL */ `
  mutation DeleteServiceType($input: DeleteServiceTypeInput!) {
    deleteServiceType(input: $input) {
      id
      name
      desc
      categoryId
      categoryName
      minutes
      price
      active
      deleted
      companyId
      s3key
      imagedesc
      offeredremote
      offeredonpremise
      isBundledService
      isVisible
      includedServices
      behavior
      ordinal
      taxexempt
      TaxOverride
      locations
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      category {
        id
        name
        desc
        companyId
        deleted
        ordinal
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      packages {
        items {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createServiceTypeCategory = /* GraphQL */ `
  mutation CreateServiceTypeCategory($input: CreateServiceTypeCategoryInput!) {
    createServiceTypeCategory(input: $input) {
      id
      name
      desc
      companyId
      deleted
      ordinal
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateServiceTypeCategory = /* GraphQL */ `
  mutation UpdateServiceTypeCategory($input: UpdateServiceTypeCategoryInput!) {
    updateServiceTypeCategory(input: $input) {
      id
      name
      desc
      companyId
      deleted
      ordinal
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteServiceTypeCategory = /* GraphQL */ `
  mutation DeleteServiceTypeCategory($input: DeleteServiceTypeCategoryInput!) {
    deleteServiceTypeCategory(input: $input) {
      id
      name
      desc
      companyId
      deleted
      ordinal
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const createRegionalPricing = /* GraphQL */ `
  mutation CreateRegionalPricing($input: CreateRegionalPricingInput!) {
    createRegionalPricing(input: $input) {
      id
      servicetypeId
      pricingtype
      country
      countryName
      province
      postalcodes
      price
      notes
      companyId
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
    }
  }
`;
export const updateRegionalPricing = /* GraphQL */ `
  mutation UpdateRegionalPricing($input: UpdateRegionalPricingInput!) {
    updateRegionalPricing(input: $input) {
      id
      servicetypeId
      pricingtype
      country
      countryName
      province
      postalcodes
      price
      notes
      companyId
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
    }
  }
`;
export const deleteRegionalPricing = /* GraphQL */ `
  mutation DeleteRegionalPricing($input: DeleteRegionalPricingInput!) {
    deleteRegionalPricing(input: $input) {
      id
      servicetypeId
      pricingtype
      country
      countryName
      province
      postalcodes
      price
      notes
      companyId
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
    }
  }
`;
export const createClient = /* GraphQL */ `
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      id
      userId
      currency
      accountbalance
      stripeCustomerId
      companyId
      prevproviders {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      defaultpartialcc
      ClientInfo
      Notes
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateClient = /* GraphQL */ `
  mutation UpdateClient($input: UpdateClientInput!) {
    updateClient(input: $input) {
      id
      userId
      currency
      accountbalance
      stripeCustomerId
      companyId
      prevproviders {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      defaultpartialcc
      ClientInfo
      Notes
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteClient = /* GraphQL */ `
  mutation DeleteClient($input: DeleteClientInput!) {
    deleteClient(input: $input) {
      id
      userId
      currency
      accountbalance
      stripeCustomerId
      companyId
      prevproviders {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      defaultpartialcc
      ClientInfo
      Notes
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const createSkill = /* GraphQL */ `
  mutation CreateSkill($input: CreateSkillInput!) {
    createSkill(input: $input) {
      id
      name
      importance
      companyId
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateSkill = /* GraphQL */ `
  mutation UpdateSkill($input: UpdateSkillInput!) {
    updateSkill(input: $input) {
      id
      name
      importance
      companyId
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteSkill = /* GraphQL */ `
  mutation DeleteSkill($input: DeleteSkillInput!) {
    deleteSkill(input: $input) {
      id
      name
      importance
      companyId
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPackage = /* GraphQL */ `
  mutation CreatePackage($input: CreatePackageInput!) {
    createPackage(input: $input) {
      id
      desc
      companyId
      servicetypeId
      packagetype
      price
      discount
      quantity
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
    }
  }
`;
export const updatePackage = /* GraphQL */ `
  mutation UpdatePackage($input: UpdatePackageInput!) {
    updatePackage(input: $input) {
      id
      desc
      companyId
      servicetypeId
      packagetype
      price
      discount
      quantity
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
    }
  }
`;
export const deletePackage = /* GraphQL */ `
  mutation DeletePackage($input: DeletePackageInput!) {
    deletePackage(input: $input) {
      id
      desc
      companyId
      servicetypeId
      packagetype
      price
      discount
      quantity
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
    }
  }
`;
export const createClientPackage = /* GraphQL */ `
  mutation CreateClientPackage($input: CreateClientPackageInput!) {
    createClientPackage(input: $input) {
      id
      companyId
      clientId
      userId
      servicetypeId
      packageId
      initialQuantity
      usedQuantity
      status
      active
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      package {
        id
        desc
        companyId
        servicetypeId
        packagetype
        price
        discount
        quantity
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
      }
      orders {
        items {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateClientPackage = /* GraphQL */ `
  mutation UpdateClientPackage($input: UpdateClientPackageInput!) {
    updateClientPackage(input: $input) {
      id
      companyId
      clientId
      userId
      servicetypeId
      packageId
      initialQuantity
      usedQuantity
      status
      active
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      package {
        id
        desc
        companyId
        servicetypeId
        packagetype
        price
        discount
        quantity
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
      }
      orders {
        items {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteClientPackage = /* GraphQL */ `
  mutation DeleteClientPackage($input: DeleteClientPackageInput!) {
    deleteClientPackage(input: $input) {
      id
      companyId
      clientId
      userId
      servicetypeId
      packageId
      initialQuantity
      usedQuantity
      status
      active
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      package {
        id
        desc
        companyId
        servicetypeId
        packagetype
        price
        discount
        quantity
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
      }
      orders {
        items {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createPromotion = /* GraphQL */ `
  mutation CreatePromotion($input: CreatePromotionInput!) {
    createPromotion(input: $input) {
      id
      name
      desc
      companyId
      amount
      promotiontype
      active
      deleted
      startdate
      enddate
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      package {
        id
        desc
        companyId
        servicetypeId
        packagetype
        price
        discount
        quantity
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updatePromotion = /* GraphQL */ `
  mutation UpdatePromotion($input: UpdatePromotionInput!) {
    updatePromotion(input: $input) {
      id
      name
      desc
      companyId
      amount
      promotiontype
      active
      deleted
      startdate
      enddate
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      package {
        id
        desc
        companyId
        servicetypeId
        packagetype
        price
        discount
        quantity
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deletePromotion = /* GraphQL */ `
  mutation DeletePromotion($input: DeletePromotionInput!) {
    deletePromotion(input: $input) {
      id
      name
      desc
      companyId
      amount
      promotiontype
      active
      deleted
      startdate
      enddate
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      package {
        id
        desc
        companyId
        servicetypeId
        packagetype
        price
        discount
        quantity
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createPromo = /* GraphQL */ `
  mutation CreatePromo($input: CreatePromoInput!) {
    createPromo(input: $input) {
      id
      recordType
      Details
      companyId
      promocode
      active
      terminated
      allPackages
      allServices
      discountType
      discountValue
      onePerClient
      startDate
      endDate
      name
      numOfRedemptions
      maxNumOfRedemptions
      hasExpiryDate
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const updatePromo = /* GraphQL */ `
  mutation UpdatePromo($input: UpdatePromoInput!) {
    updatePromo(input: $input) {
      id
      recordType
      Details
      companyId
      promocode
      active
      terminated
      allPackages
      allServices
      discountType
      discountValue
      onePerClient
      startDate
      endDate
      name
      numOfRedemptions
      maxNumOfRedemptions
      hasExpiryDate
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const deletePromo = /* GraphQL */ `
  mutation DeletePromo($input: DeletePromoInput!) {
    deletePromo(input: $input) {
      id
      recordType
      Details
      companyId
      promocode
      active
      terminated
      allPackages
      allServices
      discountType
      discountValue
      onePerClient
      startDate
      endDate
      name
      numOfRedemptions
      maxNumOfRedemptions
      hasExpiryDate
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const createBooking = /* GraphQL */ `
  mutation CreateBooking($input: CreateBookingInput!) {
    createBooking(input: $input) {
      id
      desc
      orderId
      providerId
      clientId
      startdate
      minutes
      totalAddChg
      location
      companyId
      status
      timeblockid
      cancelledAt
      noshowAt
      virtualMeetingInfo
      timezone
      TimeDisplayInfo
      orderType
      isVirtual
      manualBooking
      Notes
      MetaData
      createdAt
      updatedAt
      cancelledBy {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      noshowBy {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      bookedBy {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      rating {
        id
        providerId
        companyId
        ratingstars
        ratingtext
        ratinguserId
        active
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const updateBooking = /* GraphQL */ `
  mutation UpdateBooking($input: UpdateBookingInput!) {
    updateBooking(input: $input) {
      id
      desc
      orderId
      providerId
      clientId
      startdate
      minutes
      totalAddChg
      location
      companyId
      status
      timeblockid
      cancelledAt
      noshowAt
      virtualMeetingInfo
      timezone
      TimeDisplayInfo
      orderType
      isVirtual
      manualBooking
      Notes
      MetaData
      createdAt
      updatedAt
      cancelledBy {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      noshowBy {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      bookedBy {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      rating {
        id
        providerId
        companyId
        ratingstars
        ratingtext
        ratinguserId
        active
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const deleteBooking = /* GraphQL */ `
  mutation DeleteBooking($input: DeleteBookingInput!) {
    deleteBooking(input: $input) {
      id
      desc
      orderId
      providerId
      clientId
      startdate
      minutes
      totalAddChg
      location
      companyId
      status
      timeblockid
      cancelledAt
      noshowAt
      virtualMeetingInfo
      timezone
      TimeDisplayInfo
      orderType
      isVirtual
      manualBooking
      Notes
      MetaData
      createdAt
      updatedAt
      cancelledBy {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      noshowBy {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      bookedBy {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      rating {
        id
        providerId
        companyId
        ratingstars
        ratingtext
        ratinguserId
        active
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const createBookingStatus = /* GraphQL */ `
  mutation CreateBookingStatus($input: CreateBookingStatusInput!) {
    createBookingStatus(input: $input) {
      companyId
      status
      bookingId
      createdAt
      updatedAt
      booking {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateBookingStatus = /* GraphQL */ `
  mutation UpdateBookingStatus($input: UpdateBookingStatusInput!) {
    updateBookingStatus(input: $input) {
      companyId
      status
      bookingId
      createdAt
      updatedAt
      booking {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteBookingStatus = /* GraphQL */ `
  mutation DeleteBookingStatus($input: DeleteBookingStatusInput!) {
    deleteBookingStatus(input: $input) {
      companyId
      status
      bookingId
      createdAt
      updatedAt
      booking {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createOrder = /* GraphQL */ `
  mutation CreateOrder($input: CreateOrderInput!) {
    createOrder(input: $input) {
      id
      desc
      type
      orderNo
      companyId
      clientId
      providerId
      currency
      subtotal
      servicechargeamt
      taxamt
      total
      providerPayAmt
      status
      orderSummary
      orderReceipt
      taxrate
      bookingAddress
      legaltermsAcceptedAt
      clientnotes
      Notes
      bookedByAdmin
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      clientpackage {
        id
        companyId
        clientId
        userId
        servicetypeId
        packageId
        initialQuantity
        usedQuantity
        status
        active
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        package {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        orders {
          nextToken
        }
      }
      bookings {
        items {
          id
          desc
          orderId
          providerId
          clientId
          startdate
          minutes
          totalAddChg
          location
          companyId
          status
          timeblockid
          cancelledAt
          noshowAt
          virtualMeetingInfo
          timezone
          TimeDisplayInfo
          orderType
          isVirtual
          manualBooking
          Notes
          MetaData
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder($input: UpdateOrderInput!) {
    updateOrder(input: $input) {
      id
      desc
      type
      orderNo
      companyId
      clientId
      providerId
      currency
      subtotal
      servicechargeamt
      taxamt
      total
      providerPayAmt
      status
      orderSummary
      orderReceipt
      taxrate
      bookingAddress
      legaltermsAcceptedAt
      clientnotes
      Notes
      bookedByAdmin
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      clientpackage {
        id
        companyId
        clientId
        userId
        servicetypeId
        packageId
        initialQuantity
        usedQuantity
        status
        active
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        package {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        orders {
          nextToken
        }
      }
      bookings {
        items {
          id
          desc
          orderId
          providerId
          clientId
          startdate
          minutes
          totalAddChg
          location
          companyId
          status
          timeblockid
          cancelledAt
          noshowAt
          virtualMeetingInfo
          timezone
          TimeDisplayInfo
          orderType
          isVirtual
          manualBooking
          Notes
          MetaData
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const deleteOrder = /* GraphQL */ `
  mutation DeleteOrder($input: DeleteOrderInput!) {
    deleteOrder(input: $input) {
      id
      desc
      type
      orderNo
      companyId
      clientId
      providerId
      currency
      subtotal
      servicechargeamt
      taxamt
      total
      providerPayAmt
      status
      orderSummary
      orderReceipt
      taxrate
      bookingAddress
      legaltermsAcceptedAt
      clientnotes
      Notes
      bookedByAdmin
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      clientpackage {
        id
        companyId
        clientId
        userId
        servicetypeId
        packageId
        initialQuantity
        usedQuantity
        status
        active
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        package {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        orders {
          nextToken
        }
      }
      bookings {
        items {
          id
          desc
          orderId
          providerId
          clientId
          startdate
          minutes
          totalAddChg
          location
          companyId
          status
          timeblockid
          cancelledAt
          noshowAt
          virtualMeetingInfo
          timezone
          TimeDisplayInfo
          orderType
          isVirtual
          manualBooking
          Notes
          MetaData
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const createClientCharge = /* GraphQL */ `
  mutation CreateClientCharge($input: CreateClientChargeInput!) {
    createClientCharge(input: $input) {
      id
      clientId
      companyId
      orderId
      billingtransactionId
      description
      status
      stripechargeid
      stripepaymentmethod
      stripe_payment_method_details
      stripe_refund_charge
      stripe_status
      amount
      amount_refunded
      balance_transaction
      currency
      createdAt
      updatedAt
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
      billingtransaction {
        id
        orderId
        addservicetypeid
        amount
        creatorId
        clientId
        companyId
        desc
        credit
        approved
        createdAt
        updatedAt
        creator {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        addservicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateClientCharge = /* GraphQL */ `
  mutation UpdateClientCharge($input: UpdateClientChargeInput!) {
    updateClientCharge(input: $input) {
      id
      clientId
      companyId
      orderId
      billingtransactionId
      description
      status
      stripechargeid
      stripepaymentmethod
      stripe_payment_method_details
      stripe_refund_charge
      stripe_status
      amount
      amount_refunded
      balance_transaction
      currency
      createdAt
      updatedAt
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
      billingtransaction {
        id
        orderId
        addservicetypeid
        amount
        creatorId
        clientId
        companyId
        desc
        credit
        approved
        createdAt
        updatedAt
        creator {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        addservicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteClientCharge = /* GraphQL */ `
  mutation DeleteClientCharge($input: DeleteClientChargeInput!) {
    deleteClientCharge(input: $input) {
      id
      clientId
      companyId
      orderId
      billingtransactionId
      description
      status
      stripechargeid
      stripepaymentmethod
      stripe_payment_method_details
      stripe_refund_charge
      stripe_status
      amount
      amount_refunded
      balance_transaction
      currency
      createdAt
      updatedAt
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
      billingtransaction {
        id
        orderId
        addservicetypeid
        amount
        creatorId
        clientId
        companyId
        desc
        credit
        approved
        createdAt
        updatedAt
        creator {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        addservicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createBillingProgress = /* GraphQL */ `
  mutation CreateBillingProgress($input: CreateBillingProgressInput!) {
    createBillingProgress(input: $input) {
      companyId
      dateTime
      status
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateBillingProgress = /* GraphQL */ `
  mutation UpdateBillingProgress($input: UpdateBillingProgressInput!) {
    updateBillingProgress(input: $input) {
      companyId
      dateTime
      status
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteBillingProgress = /* GraphQL */ `
  mutation DeleteBillingProgress($input: DeleteBillingProgressInput!) {
    deleteBillingProgress(input: $input) {
      companyId
      dateTime
      status
      data
      createdAt
      updatedAt
    }
  }
`;
export const createCompanyLocation = /* GraphQL */ `
  mutation CreateCompanyLocation($input: CreateCompanyLocationInput!) {
    createCompanyLocation(input: $input) {
      id
      companyId
      locationname
      locationdesc
      locationpath
      addressoneline
      street
      city
      state
      country
      countrycode3166alpha2
      postalcode
      longitude
      latitude
      timezone
      phone
      virtual
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCompanyLocation = /* GraphQL */ `
  mutation UpdateCompanyLocation($input: UpdateCompanyLocationInput!) {
    updateCompanyLocation(input: $input) {
      id
      companyId
      locationname
      locationdesc
      locationpath
      addressoneline
      street
      city
      state
      country
      countrycode3166alpha2
      postalcode
      longitude
      latitude
      timezone
      phone
      virtual
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCompanyLocation = /* GraphQL */ `
  mutation DeleteCompanyLocation($input: DeleteCompanyLocationInput!) {
    deleteCompanyLocation(input: $input) {
      id
      companyId
      locationname
      locationdesc
      locationpath
      addressoneline
      street
      city
      state
      country
      countrycode3166alpha2
      postalcode
      longitude
      latitude
      timezone
      phone
      virtual
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const createCompanyReminder = /* GraphQL */ `
  mutation CreateCompanyReminder($input: CreateCompanyReminderInput!) {
    createCompanyReminder(input: $input) {
      id
      companyId
      reminderType
      reminderDays
      reminderHours
      reminderMinutes
      recipientType
      emailSubjectText
      emailHTMLFile
      smsText
      smsCallerId
      maxSendTime
      priority
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const updateCompanyReminder = /* GraphQL */ `
  mutation UpdateCompanyReminder($input: UpdateCompanyReminderInput!) {
    updateCompanyReminder(input: $input) {
      id
      companyId
      reminderType
      reminderDays
      reminderHours
      reminderMinutes
      recipientType
      emailSubjectText
      emailHTMLFile
      smsText
      smsCallerId
      maxSendTime
      priority
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const deleteCompanyReminder = /* GraphQL */ `
  mutation DeleteCompanyReminder($input: DeleteCompanyReminderInput!) {
    deleteCompanyReminder(input: $input) {
      id
      companyId
      reminderType
      reminderDays
      reminderHours
      reminderMinutes
      recipientType
      emailSubjectText
      emailHTMLFile
      smsText
      smsCallerId
      maxSendTime
      priority
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const createReminder = /* GraphQL */ `
  mutation CreateReminder($input: CreateReminderInput!) {
    createReminder(input: $input) {
      id
      remSendDateTimeUTC
      remSendDate
      companyId
      reminderType
      recipientType
      sendDateTime
      status
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      booking {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const updateReminder = /* GraphQL */ `
  mutation UpdateReminder($input: UpdateReminderInput!) {
    updateReminder(input: $input) {
      id
      remSendDateTimeUTC
      remSendDate
      companyId
      reminderType
      recipientType
      sendDateTime
      status
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      booking {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const deleteReminder = /* GraphQL */ `
  mutation DeleteReminder($input: DeleteReminderInput!) {
    deleteReminder(input: $input) {
      id
      remSendDateTimeUTC
      remSendDate
      companyId
      reminderType
      recipientType
      sendDateTime
      status
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      booking {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const createScheduleTimeblock = /* GraphQL */ `
  mutation CreateScheduleTimeblock($input: CreateScheduleTimeblockInput!) {
    createScheduleTimeblock(input: $input) {
      id
      scheduleinfo
      companyId
      providerId
      locationId
      servicetypeId
      active
      internal
      slotStartStep
      instructions
      postBookingInstructions
      startDate
      sdtutc
      tz
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
      deleted
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const updateScheduleTimeblock = /* GraphQL */ `
  mutation UpdateScheduleTimeblock($input: UpdateScheduleTimeblockInput!) {
    updateScheduleTimeblock(input: $input) {
      id
      scheduleinfo
      companyId
      providerId
      locationId
      servicetypeId
      active
      internal
      slotStartStep
      instructions
      postBookingInstructions
      startDate
      sdtutc
      tz
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
      deleted
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const deleteScheduleTimeblock = /* GraphQL */ `
  mutation DeleteScheduleTimeblock($input: DeleteScheduleTimeblockInput!) {
    deleteScheduleTimeblock(input: $input) {
      id
      scheduleinfo
      companyId
      providerId
      locationId
      servicetypeId
      active
      internal
      slotStartStep
      instructions
      postBookingInstructions
      startDate
      sdtutc
      tz
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
      deleted
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const createProviderSchedule = /* GraphQL */ `
  mutation CreateProviderSchedule($input: CreateProviderScheduleInput!) {
    createProviderSchedule(input: $input) {
      id
      scheduleinfo
      companyId
      providerId
      locations
      services
      active
      startDate
      sdtutc
      tz
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
      deleted
      rrule
      latitude
      longitude
      notes
      MetaData
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const updateProviderSchedule = /* GraphQL */ `
  mutation UpdateProviderSchedule($input: UpdateProviderScheduleInput!) {
    updateProviderSchedule(input: $input) {
      id
      scheduleinfo
      companyId
      providerId
      locations
      services
      active
      startDate
      sdtutc
      tz
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
      deleted
      rrule
      latitude
      longitude
      notes
      MetaData
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const deleteProviderSchedule = /* GraphQL */ `
  mutation DeleteProviderSchedule($input: DeleteProviderScheduleInput!) {
    deleteProviderSchedule(input: $input) {
      id
      scheduleinfo
      companyId
      providerId
      locations
      services
      active
      startDate
      sdtutc
      tz
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
      deleted
      rrule
      latitude
      longitude
      notes
      MetaData
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const createUISession = /* GraphQL */ `
  mutation CreateUISession($input: CreateUISessionInput!) {
    createUISession(input: $input) {
      id
      sessionItem
      SessionItemData
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const updateUISession = /* GraphQL */ `
  mutation UpdateUISession($input: UpdateUISessionInput!) {
    updateUISession(input: $input) {
      id
      sessionItem
      SessionItemData
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const deleteUISession = /* GraphQL */ `
  mutation DeleteUISession($input: DeleteUISessionInput!) {
    deleteUISession(input: $input) {
      id
      sessionItem
      SessionItemData
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const createScheduleAvailability = /* GraphQL */ `
  mutation CreateScheduleAvailability(
    $input: CreateScheduleAvailabilityInput!
  ) {
    createScheduleAvailability(input: $input) {
      schId
      statsKey
      availJson
      createdAt
      updatedAt
    }
  }
`;
export const updateScheduleAvailability = /* GraphQL */ `
  mutation UpdateScheduleAvailability(
    $input: UpdateScheduleAvailabilityInput!
  ) {
    updateScheduleAvailability(input: $input) {
      schId
      statsKey
      availJson
      createdAt
      updatedAt
    }
  }
`;
export const deleteScheduleAvailability = /* GraphQL */ `
  mutation DeleteScheduleAvailability(
    $input: DeleteScheduleAvailabilityInput!
  ) {
    deleteScheduleAvailability(input: $input) {
      schId
      statsKey
      availJson
      createdAt
      updatedAt
    }
  }
`;
export const createAdditionalCharge = /* GraphQL */ `
  mutation CreateAdditionalCharge($input: CreateAdditionalChargeInput!) {
    createAdditionalCharge(input: $input) {
      id
      amount
      desc
      note
      bookingId
      orderId
      companyId
      clientId
      creatorId
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      creator {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const updateAdditionalCharge = /* GraphQL */ `
  mutation UpdateAdditionalCharge($input: UpdateAdditionalChargeInput!) {
    updateAdditionalCharge(input: $input) {
      id
      amount
      desc
      note
      bookingId
      orderId
      companyId
      clientId
      creatorId
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      creator {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const deleteAdditionalCharge = /* GraphQL */ `
  mutation DeleteAdditionalCharge($input: DeleteAdditionalChargeInput!) {
    deleteAdditionalCharge(input: $input) {
      id
      amount
      desc
      note
      bookingId
      orderId
      companyId
      clientId
      creatorId
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      creator {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const createRepeatApptCharge = /* GraphQL */ `
  mutation CreateRepeatApptCharge($input: CreateRepeatApptChargeInput!) {
    createRepeatApptCharge(input: $input) {
      orderId
      bookingId
      companyId
      subtotal
      servicechargeamt
      taxamt
      total
      taxrate
      status
      chargeSummary
      chargeReceipt
      createdAt
      updatedAt
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const updateRepeatApptCharge = /* GraphQL */ `
  mutation UpdateRepeatApptCharge($input: UpdateRepeatApptChargeInput!) {
    updateRepeatApptCharge(input: $input) {
      orderId
      bookingId
      companyId
      subtotal
      servicechargeamt
      taxamt
      total
      taxrate
      status
      chargeSummary
      chargeReceipt
      createdAt
      updatedAt
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const deleteRepeatApptCharge = /* GraphQL */ `
  mutation DeleteRepeatApptCharge($input: DeleteRepeatApptChargeInput!) {
    deleteRepeatApptCharge(input: $input) {
      orderId
      bookingId
      companyId
      subtotal
      servicechargeamt
      taxamt
      total
      taxrate
      status
      chargeSummary
      chargeReceipt
      createdAt
      updatedAt
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const createBillingTransaction = /* GraphQL */ `
  mutation CreateBillingTransaction($input: CreateBillingTransactionInput!) {
    createBillingTransaction(input: $input) {
      id
      orderId
      addservicetypeid
      amount
      creatorId
      clientId
      companyId
      desc
      credit
      approved
      createdAt
      updatedAt
      creator {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      addservicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const updateBillingTransaction = /* GraphQL */ `
  mutation UpdateBillingTransaction($input: UpdateBillingTransactionInput!) {
    updateBillingTransaction(input: $input) {
      id
      orderId
      addservicetypeid
      amount
      creatorId
      clientId
      companyId
      desc
      credit
      approved
      createdAt
      updatedAt
      creator {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      addservicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const deleteBillingTransaction = /* GraphQL */ `
  mutation DeleteBillingTransaction($input: DeleteBillingTransactionInput!) {
    deleteBillingTransaction(input: $input) {
      id
      orderId
      addservicetypeid
      amount
      creatorId
      clientId
      companyId
      desc
      credit
      approved
      createdAt
      updatedAt
      creator {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      addservicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const createTaxRates = /* GraphQL */ `
  mutation CreateTaxRates($input: CreateTaxRatesInput!) {
    createTaxRates(input: $input) {
      id
      countrycode
      provstatecode
      rate
      createdAt
      updatedAt
    }
  }
`;
export const updateTaxRates = /* GraphQL */ `
  mutation UpdateTaxRates($input: UpdateTaxRatesInput!) {
    updateTaxRates(input: $input) {
      id
      countrycode
      provstatecode
      rate
      createdAt
      updatedAt
    }
  }
`;
export const deleteTaxRates = /* GraphQL */ `
  mutation DeleteTaxRates($input: DeleteTaxRatesInput!) {
    deleteTaxRates(input: $input) {
      id
      countrycode
      provstatecode
      rate
      createdAt
      updatedAt
    }
  }
`;
export const createRepeatApptCreation = /* GraphQL */ `
  mutation CreateRepeatApptCreation($input: CreateRepeatApptCreationInput!) {
    createRepeatApptCreation(input: $input) {
      id
      datakey
      repeatCount
      repeatUnit
      numOfApptsCreated
      active
      processedStatus
      currentEndDate
      originalStartDate
      originalEndDate
      extented
      createdAt
      updatedAt
    }
  }
`;
export const updateRepeatApptCreation = /* GraphQL */ `
  mutation UpdateRepeatApptCreation($input: UpdateRepeatApptCreationInput!) {
    updateRepeatApptCreation(input: $input) {
      id
      datakey
      repeatCount
      repeatUnit
      numOfApptsCreated
      active
      processedStatus
      currentEndDate
      originalStartDate
      originalEndDate
      extented
      createdAt
      updatedAt
    }
  }
`;
export const deleteRepeatApptCreation = /* GraphQL */ `
  mutation DeleteRepeatApptCreation($input: DeleteRepeatApptCreationInput!) {
    deleteRepeatApptCreation(input: $input) {
      id
      datakey
      repeatCount
      repeatUnit
      numOfApptsCreated
      active
      processedStatus
      currentEndDate
      originalStartDate
      originalEndDate
      extented
      createdAt
      updatedAt
    }
  }
`;
export const createEnvt = /* GraphQL */ `
  mutation CreateEnvt($input: CreateEnvtInput!) {
    createEnvt(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateEnvt = /* GraphQL */ `
  mutation UpdateEnvt($input: UpdateEnvtInput!) {
    updateEnvt(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteEnvt = /* GraphQL */ `
  mutation DeleteEnvt($input: DeleteEnvtInput!) {
    deleteEnvt(input: $input) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const createBookingRequest = /* GraphQL */ `
  mutation CreateBookingRequest($input: CreateBookingRequestInput!) {
    createBookingRequest(input: $input) {
      id
      recordType
      data
      providerId
      accepted
      acceptedBy
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateBookingRequest = /* GraphQL */ `
  mutation UpdateBookingRequest($input: UpdateBookingRequestInput!) {
    updateBookingRequest(input: $input) {
      id
      recordType
      data
      providerId
      accepted
      acceptedBy
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteBookingRequest = /* GraphQL */ `
  mutation DeleteBookingRequest($input: DeleteBookingRequestInput!) {
    deleteBookingRequest(input: $input) {
      id
      recordType
      data
      providerId
      accepted
      acceptedBy
      status
      createdAt
      updatedAt
    }
  }
`;
export const createRequestMessages = /* GraphQL */ `
  mutation CreateRequestMessages($input: CreateRequestMessagesInput!) {
    createRequestMessages(input: $input) {
      id
      recordType
      bookingRequestId
      providerId
      data
      msg
      seqNum
      status
      createdAt
      updatedAt
    }
  }
`;
export const updateRequestMessages = /* GraphQL */ `
  mutation UpdateRequestMessages($input: UpdateRequestMessagesInput!) {
    updateRequestMessages(input: $input) {
      id
      recordType
      bookingRequestId
      providerId
      data
      msg
      seqNum
      status
      createdAt
      updatedAt
    }
  }
`;
export const deleteRequestMessages = /* GraphQL */ `
  mutation DeleteRequestMessages($input: DeleteRequestMessagesInput!) {
    deleteRequestMessages(input: $input) {
      id
      recordType
      bookingRequestId
      providerId
      data
      msg
      seqNum
      status
      createdAt
      updatedAt
    }
  }
`;
export const createSystemErrorLog = /* GraphQL */ `
  mutation CreateSystemErrorLog($input: CreateSystemErrorLogInput!) {
    createSystemErrorLog(input: $input) {
      companyId
      createdAt
      severity
      source
      line
      error
      updatedAt
    }
  }
`;
export const updateSystemErrorLog = /* GraphQL */ `
  mutation UpdateSystemErrorLog($input: UpdateSystemErrorLogInput!) {
    updateSystemErrorLog(input: $input) {
      companyId
      createdAt
      severity
      source
      line
      error
      updatedAt
    }
  }
`;
export const deleteSystemErrorLog = /* GraphQL */ `
  mutation DeleteSystemErrorLog($input: DeleteSystemErrorLogInput!) {
    deleteSystemErrorLog(input: $input) {
      companyId
      createdAt
      severity
      source
      line
      error
      updatedAt
    }
  }
`;
export const createPublicAccessToken = /* GraphQL */ `
  mutation CreatePublicAccessToken($input: CreatePublicAccessTokenInput!) {
    createPublicAccessToken(input: $input) {
      id
      role
      expiryDate
      createdAt
      updatedAt
    }
  }
`;
export const updatePublicAccessToken = /* GraphQL */ `
  mutation UpdatePublicAccessToken($input: UpdatePublicAccessTokenInput!) {
    updatePublicAccessToken(input: $input) {
      id
      role
      expiryDate
      createdAt
      updatedAt
    }
  }
`;
export const deletePublicAccessToken = /* GraphQL */ `
  mutation DeletePublicAccessToken($input: DeletePublicAccessTokenInput!) {
    deletePublicAccessToken(input: $input) {
      id
      role
      expiryDate
      createdAt
      updatedAt
    }
  }
`;
export const createRefData = /* GraphQL */ `
  mutation CreateRefData($input: CreateRefDataInput!) {
    createRefData(input: $input) {
      refType
      refName
      refValue
      refInt
      overrideValue
      createdAt
      updatedAt
    }
  }
`;
export const updateRefData = /* GraphQL */ `
  mutation UpdateRefData($input: UpdateRefDataInput!) {
    updateRefData(input: $input) {
      refType
      refName
      refValue
      refInt
      overrideValue
      createdAt
      updatedAt
    }
  }
`;
export const deleteRefData = /* GraphQL */ `
  mutation DeleteRefData($input: DeleteRefDataInput!) {
    deleteRefData(input: $input) {
      refType
      refName
      refValue
      refInt
      overrideValue
      createdAt
      updatedAt
    }
  }
`;
export const createStatistic = /* GraphQL */ `
  mutation CreateStatistic($input: CreateStatisticInput!) {
    createStatistic(input: $input) {
      id
      dataPoint
      amount
      count
      createdAt
      updatedAt
    }
  }
`;
export const updateStatistic = /* GraphQL */ `
  mutation UpdateStatistic($input: UpdateStatisticInput!) {
    updateStatistic(input: $input) {
      id
      dataPoint
      amount
      count
      createdAt
      updatedAt
    }
  }
`;
export const deleteStatistic = /* GraphQL */ `
  mutation DeleteStatistic($input: DeleteStatisticInput!) {
    deleteStatistic(input: $input) {
      id
      dataPoint
      amount
      count
      createdAt
      updatedAt
    }
  }
`;
export const createProspect = /* GraphQL */ `
  mutation CreateProspect($input: CreateProspectInput!) {
    createProspect(input: $input) {
      companyId
      prospectId
      emailaddress
      firstname
      lastname
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      pageUrl
      message
      SearchParamsInfo
      createdAt
      updatedAt
    }
  }
`;
export const updateProspect = /* GraphQL */ `
  mutation UpdateProspect($input: UpdateProspectInput!) {
    updateProspect(input: $input) {
      companyId
      prospectId
      emailaddress
      firstname
      lastname
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      pageUrl
      message
      SearchParamsInfo
      createdAt
      updatedAt
    }
  }
`;
export const deleteProspect = /* GraphQL */ `
  mutation DeleteProspect($input: DeleteProspectInput!) {
    deleteProspect(input: $input) {
      companyId
      prospectId
      emailaddress
      firstname
      lastname
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      pageUrl
      message
      SearchParamsInfo
      createdAt
      updatedAt
    }
  }
`;
export const createManualBooking = /* GraphQL */ `
  mutation CreateManualBooking($input: CreateManualBookingInput!) {
    createManualBooking(input: $input) {
      companyId
      recordId
      emailaddress
      firstname
      lastname
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      message
      MetadataInfo
      createdAt
      updatedAt
    }
  }
`;
export const updateManualBooking = /* GraphQL */ `
  mutation UpdateManualBooking($input: UpdateManualBookingInput!) {
    updateManualBooking(input: $input) {
      companyId
      recordId
      emailaddress
      firstname
      lastname
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      message
      MetadataInfo
      createdAt
      updatedAt
    }
  }
`;
export const deleteManualBooking = /* GraphQL */ `
  mutation DeleteManualBooking($input: DeleteManualBookingInput!) {
    deleteManualBooking(input: $input) {
      companyId
      recordId
      emailaddress
      firstname
      lastname
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      message
      MetadataInfo
      createdAt
      updatedAt
    }
  }
`;
export const createAudit = /* GraphQL */ `
  mutation CreateAudit($input: CreateAuditInput!) {
    createAudit(input: $input) {
      companyIdUserId
      datetime
      compResId
      resType
      companyId
      userFN
      userLN
      userEmail
      userRole
      userAction
      data
      createdAt
      updatedAt
    }
  }
`;
export const updateAudit = /* GraphQL */ `
  mutation UpdateAudit($input: UpdateAuditInput!) {
    updateAudit(input: $input) {
      companyIdUserId
      datetime
      compResId
      resType
      companyId
      userFN
      userLN
      userEmail
      userRole
      userAction
      data
      createdAt
      updatedAt
    }
  }
`;
export const deleteAudit = /* GraphQL */ `
  mutation DeleteAudit($input: DeleteAuditInput!) {
    deleteAudit(input: $input) {
      companyIdUserId
      datetime
      compResId
      resType
      companyId
      userFN
      userLN
      userEmail
      userRole
      userAction
      data
      createdAt
      updatedAt
    }
  }
`;
export const createIntegrationToken = /* GraphQL */ `
  mutation CreateIntegrationToken($input: CreateIntegrationTokenInput!) {
    createIntegrationToken(input: $input) {
      companyIdProviderId
      serviceName
      TokenInfo
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const updateIntegrationToken = /* GraphQL */ `
  mutation UpdateIntegrationToken($input: UpdateIntegrationTokenInput!) {
    updateIntegrationToken(input: $input) {
      companyIdProviderId
      serviceName
      TokenInfo
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const deleteIntegrationToken = /* GraphQL */ `
  mutation DeleteIntegrationToken($input: DeleteIntegrationTokenInput!) {
    deleteIntegrationToken(input: $input) {
      companyIdProviderId
      serviceName
      TokenInfo
      companyId
      createdAt
      updatedAt
    }
  }
`;
