/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUserRegistration = /* GraphQL */ `
  query GetUserRegistration($id: ID!) {
    getUserRegistration(id: $id) {
      id
      emailaddress
      registrationInfo
      createdAt
      updatedAt
    }
  }
`;
export const listUserRegistrations = /* GraphQL */ `
  query ListUserRegistrations(
    $filter: ModelUserRegistrationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRegistrations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        emailaddress
        registrationInfo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyPublicSite = /* GraphQL */ `
  query GetCompanyPublicSite($id: ID!) {
    getCompanyPublicSite(id: $id) {
      id
      companyId
      backgroundImageUrl
      heading1Text
      heading2Text
      textColor
      buttonColor
      StyleCustomization
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const listCompanyPublicSites = /* GraphQL */ `
  query ListCompanyPublicSites(
    $filter: ModelCompanyPublicSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyPublicSites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        backgroundImageUrl
        heading1Text
        heading2Text
        textColor
        buttonColor
        StyleCustomization
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const publicsiteByCompany = /* GraphQL */ `
  query PublicsiteByCompany(
    $companyId: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyPublicSiteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    publicsiteByCompany(
      companyId: $companyId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        backgroundImageUrl
        heading1Text
        heading2Text
        textColor
        buttonColor
        StyleCustomization
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      username
      emailaddress
      registered
      firstname
      lastname
      homephone
      workphone
      mobilephone
      phonepref
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      role
      companyId
      providerId
      active
      deleted
      contactconsent
      contactconsentdatetime
      LoginInfo
      SalesInfo
      PNInfo
      lc_name
      lc_emailaddress
      lc_ln
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userByCompany = /* GraphQL */ `
  query UserByCompany(
    $companyId: String
    $roleEmailaddress: ModelUserByCompanyCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByCompany(
      companyId: $companyId
      roleEmailaddress: $roleEmailaddress
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchByName = /* GraphQL */ `
  query SearchByName(
    $companyId: String
    $lc_name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByName(
      companyId: $companyId
      lc_name: $lc_name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchByLastName = /* GraphQL */ `
  query SearchByLastName(
    $companyId: String
    $lc_ln: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByLastName(
      companyId: $companyId
      lc_ln: $lc_ln
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const userByProviderId = /* GraphQL */ `
  query UserByProviderId(
    $companyId: String
    $providerId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByProviderId(
      companyId: $companyId
      providerId: $providerId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: SearchableUserSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getUserRemoteAddress = /* GraphQL */ `
  query GetUserRemoteAddress($id: ID!) {
    getUserRemoteAddress(id: $id) {
      id
      userId
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      active
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listUserRemoteAddresss = /* GraphQL */ `
  query ListUserRemoteAddresss(
    $filter: ModelUserRemoteAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRemoteAddresss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        active
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUserInvitation = /* GraphQL */ `
  query GetUserInvitation($id: ID!) {
    getUserInvitation(id: $id) {
      id
      emailaddress
      invitecode
      role
      active
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const listUserInvitations = /* GraphQL */ `
  query ListUserInvitations(
    $filter: ModelUserInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserInvitations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        emailaddress
        invitecode
        role
        active
        companyId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchUserInvitations = /* GraphQL */ `
  query SearchUserInvitations(
    $filter: SearchableUserInvitationFilterInput
    $sort: SearchableUserInvitationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchUserInvitations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        emailaddress
        invitecode
        role
        active
        companyId
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      name
      contactname
      emailaddress
      currency
      currencyBasedOnLocation
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      clientcanselectprovider
      active
      offersRemoteServices
      offersOnPremiseServices
      offersVirtualServices
      providerMustAcceptAppt
      useAnonymousPermalink
      multipleServices
      multipleQty
      ApptAcceptanceFlowConfig
      collectpayment
      subdomain
      tagline
      logoUrl
      billingMode
      iframeURL
      primaryColor
      addServiceFee
      serviceFeeType
      serviceFeeAmount
      taxrate
      travelTime
      bookingIntervalMinutes
      countrycode3166alpha2
      publishableStripeKey
      displayOnlyLogo
      sendratingTipMsg
      ratingTipTextMsg
      offersPackagesOnly
      forceRepeatingPackages
      offersForeverAppt
      singleApptOnly
      virtualMeetingConfig
      DashboardInfo
      BccLists
      maskeddomain
      replyemailaddress
      homepage
      clientnotesheading
      hideRatingsFromClients
      subscriptionLevel
      cancelPolicyMinsBefore
      bookingIncrement
      providerCanCancelAppt
      noProviderMsg
      displayProviderRatios
      PaymentSettings
      stripeConnectEnabled
      stripeAccount
      setupCompleted
      clientCanReschedule
      providerCanReschedule
      ProvAgnoFlowConfig
      SuggestionConfig
      createdAt
      updatedAt
    }
  }
`;
export const listCompanys = /* GraphQL */ `
  query ListCompanys(
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const companyBySubdomain = /* GraphQL */ `
  query CompanyBySubdomain(
    $subdomain: String
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyBySubdomain(
      subdomain: $subdomain
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchCompanys = /* GraphQL */ `
  query SearchCompanys(
    $filter: SearchableCompanyFilterInput
    $sort: SearchableCompanySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCompanys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getPendingCompany = /* GraphQL */ `
  query GetPendingCompany($id: ID!) {
    getPendingCompany(id: $id) {
      id
      name
      contactname
      emailaddress
      subdomain
      publishableStripeKey
      subscriptionLevel
      estimatedNumOfProviders
      status
      PaymentSettings
      createdAt
      updatedAt
    }
  }
`;
export const listPendingCompanys = /* GraphQL */ `
  query ListPendingCompanys(
    $filter: ModelPendingCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPendingCompanys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        contactname
        emailaddress
        subdomain
        publishableStripeKey
        subscriptionLevel
        estimatedNumOfProviders
        status
        PaymentSettings
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const companyByEmailAddress = /* GraphQL */ `
  query CompanyByEmailAddress(
    $emailaddress: AWSEmail
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPendingCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyByEmailAddress(
      emailaddress: $emailaddress
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        contactname
        emailaddress
        subdomain
        publishableStripeKey
        subscriptionLevel
        estimatedNumOfProviders
        status
        PaymentSettings
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProvider = /* GraphQL */ `
  query GetProvider($id: ID!) {
    getProvider(id: $id) {
      id
      firstname
      lc_fn
      lastname
      lc_ln
      emailaddress
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      timezone
      bio
      pictures3key
      maxtraveltype
      companyId
      numberofschedules
      offersMobileServices
      active
      deleted
      ratingstarsavg
      numberofratings
      offersVirtualServices
      virtualMeetingUserId
      vmlink
      permalink
      AcceptanceRatios
      userId
      maxTravelTime
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      locations {
        items {
          id
          providerId
          companyId
          locationpath
          name
          desc
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          maxtraveltype
          traveldistance
          traveldistanceunit
          travelregions
          active
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
      servicetypes {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      skills {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listProviders = /* GraphQL */ `
  query ListProviders(
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const providerByCompany = /* GraphQL */ `
  query ProviderByCompany(
    $companyId: String
    $firstnameLastnameCreatedAt: ModelProviderByCompanyCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providerByCompany(
      companyId: $companyId
      firstnameLastnameCreatedAt: $firstnameLastnameCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const providerByPermalink = /* GraphQL */ `
  query ProviderByPermalink(
    $companyId: String
    $permalink: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providerByPermalink(
      companyId: $companyId
      permalink: $permalink
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchByFirstName = /* GraphQL */ `
  query SearchByFirstName(
    $companyId: String
    $lc_fn: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchByFirstName(
      companyId: $companyId
      lc_fn: $lc_fn
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchProviderByLastName = /* GraphQL */ `
  query SearchProviderByLastName(
    $companyId: String
    $lc_ln: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProviderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    searchProviderByLastName(
      companyId: $companyId
      lc_ln: $lc_ln
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchProviders = /* GraphQL */ `
  query SearchProviders(
    $filter: SearchableProviderFilterInput
    $sort: SearchableProviderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProviders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getProviderLocation = /* GraphQL */ `
  query GetProviderLocation($id: ID!) {
    getProviderLocation(id: $id) {
      id
      providerId
      companyId
      locationpath
      name
      desc
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      timezone
      maxtraveltype
      traveldistance
      traveldistanceunit
      travelregions
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const listProviderLocations = /* GraphQL */ `
  query ListProviderLocations(
    $filter: ModelProviderLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviderLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        providerId
        companyId
        locationpath
        name
        desc
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        maxtraveltype
        traveldistance
        traveldistanceunit
        travelregions
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const locationByProvider = /* GraphQL */ `
  query LocationByProvider(
    $providerId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProviderLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationByProvider(
      providerId: $providerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        providerId
        companyId
        locationpath
        name
        desc
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        maxtraveltype
        traveldistance
        traveldistanceunit
        travelregions
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const providerLocationPathByCompany = /* GraphQL */ `
  query ProviderLocationPathByCompany(
    $companyId: String
    $locationpath: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProviderLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providerLocationPathByCompany(
      companyId: $companyId
      locationpath: $locationpath
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        providerId
        companyId
        locationpath
        name
        desc
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        maxtraveltype
        traveldistance
        traveldistanceunit
        travelregions
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchProviderLocations = /* GraphQL */ `
  query SearchProviderLocations(
    $filter: SearchableProviderLocationFilterInput
    $sort: SearchableProviderLocationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProviderLocations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        providerId
        companyId
        locationpath
        name
        desc
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        maxtraveltype
        traveldistance
        traveldistanceunit
        travelregions
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getProviderPhotos = /* GraphQL */ `
  query GetProviderPhotos($id: ID!) {
    getProviderPhotos(id: $id) {
      id
      providerId
      name
      desc
      s3key
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const listProviderPhotoss = /* GraphQL */ `
  query ListProviderPhotoss(
    $filter: ModelProviderPhotosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviderPhotoss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        providerId
        name
        desc
        s3key
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const providerPhotosByProvider = /* GraphQL */ `
  query ProviderPhotosByProvider(
    $providerId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProviderPhotosFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providerPhotosByProvider(
      providerId: $providerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        providerId
        name
        desc
        s3key
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchProviderPhotoss = /* GraphQL */ `
  query SearchProviderPhotoss(
    $filter: SearchableProviderPhotosFilterInput
    $sort: SearchableProviderPhotosSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProviderPhotoss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        providerId
        name
        desc
        s3key
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getProviderRatings = /* GraphQL */ `
  query GetProviderRatings($id: ID!) {
    getProviderRatings(id: $id) {
      id
      providerId
      companyId
      ratingstars
      ratingtext
      ratinguserId
      active
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const listProviderRatingss = /* GraphQL */ `
  query ListProviderRatingss(
    $filter: ModelProviderRatingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProviderRatingss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        providerId
        companyId
        ratingstars
        ratingtext
        ratinguserId
        active
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const providerRatingsByProvider = /* GraphQL */ `
  query ProviderRatingsByProvider(
    $providerId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProviderRatingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providerRatingsByProvider(
      providerId: $providerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        providerId
        companyId
        ratingstars
        ratingtext
        ratinguserId
        active
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const providerRatingsByCompany = /* GraphQL */ `
  query ProviderRatingsByCompany(
    $companyId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProviderRatingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providerRatingsByCompany(
      companyId: $companyId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        providerId
        companyId
        ratingstars
        ratingtext
        ratinguserId
        active
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchProviderRatingss = /* GraphQL */ `
  query SearchProviderRatingss(
    $filter: SearchableProviderRatingsFilterInput
    $sort: SearchableProviderRatingsSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProviderRatingss(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        providerId
        companyId
        ratingstars
        ratingtext
        ratinguserId
        active
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getServiceType = /* GraphQL */ `
  query GetServiceType($id: ID!) {
    getServiceType(id: $id) {
      id
      name
      desc
      categoryId
      categoryName
      minutes
      price
      active
      deleted
      companyId
      s3key
      imagedesc
      offeredremote
      offeredonpremise
      isBundledService
      isVisible
      includedServices
      behavior
      ordinal
      taxexempt
      TaxOverride
      locations
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      category {
        id
        name
        desc
        companyId
        deleted
        ordinal
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      packages {
        items {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listServiceTypes = /* GraphQL */ `
  query ListServiceTypes(
    $filter: ModelServiceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const serviceTypeByCompany = /* GraphQL */ `
  query ServiceTypeByCompany(
    $companyId: String
    $categoryNameName: ModelServiceTypeByCompanySortedByCategoryNameNameCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceTypeByCompany(
      companyId: $companyId
      categoryNameName: $categoryNameName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const bundledServicesByCompany = /* GraphQL */ `
  query BundledServicesByCompany(
    $companyId: String
    $includedServices: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bundledServicesByCompany(
      companyId: $companyId
      includedServices: $includedServices
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchServiceTypes = /* GraphQL */ `
  query SearchServiceTypes(
    $filter: SearchableServiceTypeFilterInput
    $sort: SearchableServiceTypeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchServiceTypes(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getServiceTypeCategory = /* GraphQL */ `
  query GetServiceTypeCategory($id: ID!) {
    getServiceTypeCategory(id: $id) {
      id
      name
      desc
      companyId
      deleted
      ordinal
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const listServiceTypeCategorys = /* GraphQL */ `
  query ListServiceTypeCategorys(
    $filter: ModelServiceTypeCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listServiceTypeCategorys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        desc
        companyId
        deleted
        ordinal
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const serviceTypeCategoryByCompany = /* GraphQL */ `
  query ServiceTypeCategoryByCompany(
    $companyId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelServiceTypeCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    serviceTypeCategoryByCompany(
      companyId: $companyId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        desc
        companyId
        deleted
        ordinal
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchServiceTypeCategorys = /* GraphQL */ `
  query SearchServiceTypeCategorys(
    $filter: SearchableServiceTypeCategoryFilterInput
    $sort: SearchableServiceTypeCategorySortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchServiceTypeCategorys(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        desc
        companyId
        deleted
        ordinal
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getRegionalPricing = /* GraphQL */ `
  query GetRegionalPricing($id: ID!) {
    getRegionalPricing(id: $id) {
      id
      servicetypeId
      pricingtype
      country
      countryName
      province
      postalcodes
      price
      notes
      companyId
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
    }
  }
`;
export const listRegionalPricings = /* GraphQL */ `
  query ListRegionalPricings(
    $filter: ModelRegionalPricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRegionalPricings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        servicetypeId
        pricingtype
        country
        countryName
        province
        postalcodes
        price
        notes
        companyId
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const regionalPricingByCompanyServiceType = /* GraphQL */ `
  query RegionalPricingByCompanyServiceType(
    $companyId: String
    $servicetypeId: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelRegionalPricingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    regionalPricingByCompanyServiceType(
      companyId: $companyId
      servicetypeId: $servicetypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        servicetypeId
        pricingtype
        country
        countryName
        province
        postalcodes
        price
        notes
        companyId
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      id
      userId
      currency
      accountbalance
      stripeCustomerId
      companyId
      prevproviders {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      defaultpartialcc
      ClientInfo
      Notes
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const listClients = /* GraphQL */ `
  query ListClients(
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const clientByUserId = /* GraphQL */ `
  query ClientByUserId(
    $userId: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientByUserId(
      userId: $userId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const clientByCompany = /* GraphQL */ `
  query ClientByCompany(
    $companyId: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientByCompany(
      companyId: $companyId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchClients = /* GraphQL */ `
  query SearchClients(
    $filter: SearchableClientFilterInput
    $sort: SearchableClientSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchClients(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getSkill = /* GraphQL */ `
  query GetSkill($id: ID!) {
    getSkill(id: $id) {
      id
      name
      importance
      companyId
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      providers {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        importance
        companyId
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const skillByCompany = /* GraphQL */ `
  query SkillByCompany(
    $companyId: String
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    skillByCompany(
      companyId: $companyId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        importance
        companyId
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchSkills = /* GraphQL */ `
  query SearchSkills(
    $filter: SearchableSkillFilterInput
    $sort: SearchableSkillSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchSkills(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        importance
        companyId
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getPackage = /* GraphQL */ `
  query GetPackage($id: ID!) {
    getPackage(id: $id) {
      id
      desc
      companyId
      servicetypeId
      packagetype
      price
      discount
      quantity
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
    }
  }
`;
export const listPackages = /* GraphQL */ `
  query ListPackages(
    $filter: ModelPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        desc
        companyId
        servicetypeId
        packagetype
        price
        discount
        quantity
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const packageByCompany = /* GraphQL */ `
  query PackageByCompany(
    $companyId: String
    $servicetypeIdCreatedAt: ModelPackageByCompanyCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    packageByCompany(
      companyId: $companyId
      servicetypeIdCreatedAt: $servicetypeIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        desc
        companyId
        servicetypeId
        packagetype
        price
        discount
        quantity
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchPackages = /* GraphQL */ `
  query SearchPackages(
    $filter: SearchablePackageFilterInput
    $sort: SearchablePackageSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPackages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        desc
        companyId
        servicetypeId
        packagetype
        price
        discount
        quantity
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getClientPackage = /* GraphQL */ `
  query GetClientPackage($id: ID!) {
    getClientPackage(id: $id) {
      id
      companyId
      clientId
      userId
      servicetypeId
      packageId
      initialQuantity
      usedQuantity
      status
      active
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      package {
        id
        desc
        companyId
        servicetypeId
        packagetype
        price
        discount
        quantity
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
      }
      orders {
        items {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listClientPackages = /* GraphQL */ `
  query ListClientPackages(
    $filter: ModelClientPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        companyId
        clientId
        userId
        servicetypeId
        packageId
        initialQuantity
        usedQuantity
        status
        active
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        package {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        orders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const clientPackageByUser = /* GraphQL */ `
  query ClientPackageByUser(
    $userId: String
    $servicetypeIdPackageIdCreatedAt: ModelClientPackageByUserCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientPackageByUser(
      userId: $userId
      servicetypeIdPackageIdCreatedAt: $servicetypeIdPackageIdCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        clientId
        userId
        servicetypeId
        packageId
        initialQuantity
        usedQuantity
        status
        active
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        package {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        orders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const clientPackageByCompany = /* GraphQL */ `
  query ClientPackageByCompany(
    $companyId: String
    $createdAtId: ModelClientPackageByCompanyCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientPackageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientPackageByCompany(
      companyId: $companyId
      createdAtId: $createdAtId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        clientId
        userId
        servicetypeId
        packageId
        initialQuantity
        usedQuantity
        status
        active
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        package {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        orders {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchClientPackages = /* GraphQL */ `
  query SearchClientPackages(
    $filter: SearchableClientPackageFilterInput
    $sort: SearchableClientPackageSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchClientPackages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        companyId
        clientId
        userId
        servicetypeId
        packageId
        initialQuantity
        usedQuantity
        status
        active
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        package {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        orders {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getPromotion = /* GraphQL */ `
  query GetPromotion($id: ID!) {
    getPromotion(id: $id) {
      id
      name
      desc
      companyId
      amount
      promotiontype
      active
      deleted
      startdate
      enddate
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      package {
        id
        desc
        companyId
        servicetypeId
        packagetype
        price
        discount
        quantity
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listPromotions = /* GraphQL */ `
  query ListPromotions(
    $filter: ModelPromotionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromotions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        desc
        companyId
        amount
        promotiontype
        active
        deleted
        startdate
        enddate
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        package {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchPromotions = /* GraphQL */ `
  query SearchPromotions(
    $filter: SearchablePromotionFilterInput
    $sort: SearchablePromotionSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchPromotions(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        desc
        companyId
        amount
        promotiontype
        active
        deleted
        startdate
        enddate
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        package {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getPromo = /* GraphQL */ `
  query GetPromo($id: ID!, $recordType: String!) {
    getPromo(id: $id, recordType: $recordType) {
      id
      recordType
      Details
      companyId
      promocode
      active
      terminated
      allPackages
      allServices
      discountType
      discountValue
      onePerClient
      startDate
      endDate
      name
      numOfRedemptions
      maxNumOfRedemptions
      hasExpiryDate
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const listPromos = /* GraphQL */ `
  query ListPromos(
    $id: ID
    $recordType: ModelStringKeyConditionInput
    $filter: ModelPromoFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPromos(
      id: $id
      recordType: $recordType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        recordType
        Details
        companyId
        promocode
        active
        terminated
        allPackages
        allServices
        discountType
        discountValue
        onePerClient
        startDate
        endDate
        name
        numOfRedemptions
        maxNumOfRedemptions
        hasExpiryDate
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const promocodeByCompany = /* GraphQL */ `
  query PromocodeByCompany(
    $companyId: String
    $promocodeId: ModelPromoByCompanyPromocodeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPromoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    promocodeByCompany(
      companyId: $companyId
      promocodeId: $promocodeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordType
        Details
        companyId
        promocode
        active
        terminated
        allPackages
        allServices
        discountType
        discountValue
        onePerClient
        startDate
        endDate
        name
        numOfRedemptions
        maxNumOfRedemptions
        hasExpiryDate
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const promocodeRecordTypeByCompany = /* GraphQL */ `
  query PromocodeRecordTypeByCompany(
    $companyId: String
    $promocodeRecordType: ModelPromoByCompanyPromocodeRecordtypeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPromoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    promocodeRecordTypeByCompany(
      companyId: $companyId
      promocodeRecordType: $promocodeRecordType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordType
        Details
        companyId
        promocode
        active
        terminated
        allPackages
        allServices
        discountType
        discountValue
        onePerClient
        startDate
        endDate
        name
        numOfRedemptions
        maxNumOfRedemptions
        hasExpiryDate
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const promoByCompany = /* GraphQL */ `
  query PromoByCompany(
    $companyId: String
    $idRecordType: ModelPromoByCompanyPromosCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPromoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    promoByCompany(
      companyId: $companyId
      idRecordType: $idRecordType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordType
        Details
        companyId
        promocode
        active
        terminated
        allPackages
        allServices
        discountType
        discountValue
        onePerClient
        startDate
        endDate
        name
        numOfRedemptions
        maxNumOfRedemptions
        hasExpiryDate
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const promoSettingsByCompany = /* GraphQL */ `
  query PromoSettingsByCompany(
    $companyId: String
    $recordTypeId: ModelPromoByCompanyPromoSettingsCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPromoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    promoSettingsByCompany(
      companyId: $companyId
      recordTypeId: $recordTypeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        recordType
        Details
        companyId
        promocode
        active
        terminated
        allPackages
        allServices
        discountType
        discountValue
        onePerClient
        startDate
        endDate
        name
        numOfRedemptions
        maxNumOfRedemptions
        hasExpiryDate
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getBooking = /* GraphQL */ `
  query GetBooking($id: ID!) {
    getBooking(id: $id) {
      id
      desc
      orderId
      providerId
      clientId
      startdate
      minutes
      totalAddChg
      location
      companyId
      status
      timeblockid
      cancelledAt
      noshowAt
      virtualMeetingInfo
      timezone
      TimeDisplayInfo
      orderType
      isVirtual
      manualBooking
      Notes
      MetaData
      createdAt
      updatedAt
      cancelledBy {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      noshowBy {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      bookedBy {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      rating {
        id
        providerId
        companyId
        ratingstars
        ratingtext
        ratinguserId
        active
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      servicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const listBookings = /* GraphQL */ `
  query ListBookings(
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBookings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const bookingByProvider = /* GraphQL */ `
  query BookingByProvider(
    $providerId: String
    $startdateTimeblockid: ModelBookingByProviderCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByProvider(
      providerId: $providerId
      startdateTimeblockid: $startdateTimeblockid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const bookingByCompany = /* GraphQL */ `
  query BookingByCompany(
    $companyId: String
    $startdateTimeblockid: ModelBookingByCompanyCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByCompany(
      companyId: $companyId
      startdateTimeblockid: $startdateTimeblockid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const bookingByOrder = /* GraphQL */ `
  query BookingByOrder(
    $orderId: String
    $startdateTimeblockid: ModelBookingByOrderCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByOrder(
      orderId: $orderId
      startdateTimeblockid: $startdateTimeblockid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const bookingByClient = /* GraphQL */ `
  query BookingByClient(
    $clientId: String
    $startdateTimeblockid: ModelBookingByClientCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    bookingByClient(
      clientId: $clientId
      startdateTimeblockid: $startdateTimeblockid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchBookings = /* GraphQL */ `
  query SearchBookings(
    $filter: SearchableBookingFilterInput
    $sort: SearchableBookingSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchBookings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getBookingStatus = /* GraphQL */ `
  query GetBookingStatus($companyId: ID!, $status: String!) {
    getBookingStatus(companyId: $companyId, status: $status) {
      companyId
      status
      bookingId
      createdAt
      updatedAt
      booking {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listBookingStatuss = /* GraphQL */ `
  query ListBookingStatuss(
    $companyId: ID
    $status: ModelStringKeyConditionInput
    $filter: ModelBookingStatusFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBookingStatuss(
      companyId: $companyId
      status: $status
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        companyId
        status
        bookingId
        createdAt
        updatedAt
        booking {
          id
          desc
          orderId
          providerId
          clientId
          startdate
          minutes
          totalAddChg
          location
          companyId
          status
          timeblockid
          cancelledAt
          noshowAt
          virtualMeetingInfo
          timezone
          TimeDisplayInfo
          orderType
          isVirtual
          manualBooking
          Notes
          MetaData
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const statusByBooking = /* GraphQL */ `
  query StatusByBooking(
    $bookingId: String
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelBookingStatusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    statusByBooking(
      bookingId: $bookingId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        companyId
        status
        bookingId
        createdAt
        updatedAt
        booking {
          id
          desc
          orderId
          providerId
          clientId
          startdate
          minutes
          totalAddChg
          location
          companyId
          status
          timeblockid
          cancelledAt
          noshowAt
          virtualMeetingInfo
          timezone
          TimeDisplayInfo
          orderType
          isVirtual
          manualBooking
          Notes
          MetaData
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      id
      desc
      type
      orderNo
      companyId
      clientId
      providerId
      currency
      subtotal
      servicechargeamt
      taxamt
      total
      providerPayAmt
      status
      orderSummary
      orderReceipt
      taxrate
      bookingAddress
      legaltermsAcceptedAt
      clientnotes
      Notes
      bookedByAdmin
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      clientpackage {
        id
        companyId
        clientId
        userId
        servicetypeId
        packageId
        initialQuantity
        usedQuantity
        status
        active
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        package {
          id
          desc
          companyId
          servicetypeId
          packagetype
          price
          discount
          quantity
          active
          deleted
          createdAt
          updatedAt
        }
        orders {
          nextToken
        }
      }
      bookings {
        items {
          id
          desc
          orderId
          providerId
          clientId
          startdate
          minutes
          totalAddChg
          location
          companyId
          status
          timeblockid
          cancelledAt
          noshowAt
          virtualMeetingInfo
          timezone
          TimeDisplayInfo
          orderType
          isVirtual
          manualBooking
          Notes
          MetaData
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const ordersByCompanyOrderNo = /* GraphQL */ `
  query OrdersByCompanyOrderNo(
    $companyId: String
    $orderNo: ModelIntKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByCompanyOrderNo(
      companyId: $companyId
      orderNo: $orderNo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const ordersByClientCreatedAt = /* GraphQL */ `
  query OrdersByClientCreatedAt(
    $clientId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByClientCreatedAt(
      clientId: $clientId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const ordersByProviderCreatedAt = /* GraphQL */ `
  query OrdersByProviderCreatedAt(
    $providerId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOrderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ordersByProviderCreatedAt(
      providerId: $providerId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const searchOrders = /* GraphQL */ `
  query SearchOrders(
    $filter: SearchableOrderFilterInput
    $sort: SearchableOrderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchOrders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
      nextToken
      total
    }
  }
`;
export const getClientCharge = /* GraphQL */ `
  query GetClientCharge($id: ID!) {
    getClientCharge(id: $id) {
      id
      clientId
      companyId
      orderId
      billingtransactionId
      description
      status
      stripechargeid
      stripepaymentmethod
      stripe_payment_method_details
      stripe_refund_charge
      stripe_status
      amount
      amount_refunded
      balance_transaction
      currency
      createdAt
      updatedAt
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
      billingtransaction {
        id
        orderId
        addservicetypeid
        amount
        creatorId
        clientId
        companyId
        desc
        credit
        approved
        createdAt
        updatedAt
        creator {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        addservicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listClientCharges = /* GraphQL */ `
  query ListClientCharges(
    $filter: ModelClientChargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientCharges(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        clientId
        companyId
        orderId
        billingtransactionId
        description
        status
        stripechargeid
        stripepaymentmethod
        stripe_payment_method_details
        stripe_refund_charge
        stripe_status
        amount
        amount_refunded
        balance_transaction
        currency
        createdAt
        updatedAt
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
        billingtransaction {
          id
          orderId
          addservicetypeid
          amount
          creatorId
          clientId
          companyId
          desc
          credit
          approved
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const clientChargeByOrder = /* GraphQL */ `
  query ClientChargeByOrder(
    $orderId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientChargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientChargeByOrder(
      orderId: $orderId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        companyId
        orderId
        billingtransactionId
        description
        status
        stripechargeid
        stripepaymentmethod
        stripe_payment_method_details
        stripe_refund_charge
        stripe_status
        amount
        amount_refunded
        balance_transaction
        currency
        createdAt
        updatedAt
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
        billingtransaction {
          id
          orderId
          addservicetypeid
          amount
          creatorId
          clientId
          companyId
          desc
          credit
          approved
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const clientChargeByCompany = /* GraphQL */ `
  query ClientChargeByCompany(
    $companyId: String
    $createdAtId: ModelClientChargeByCompanyCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientChargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    clientChargeByCompany(
      companyId: $companyId
      createdAtId: $createdAtId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        clientId
        companyId
        orderId
        billingtransactionId
        description
        status
        stripechargeid
        stripepaymentmethod
        stripe_payment_method_details
        stripe_refund_charge
        stripe_status
        amount
        amount_refunded
        balance_transaction
        currency
        createdAt
        updatedAt
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
        billingtransaction {
          id
          orderId
          addservicetypeid
          amount
          creatorId
          clientId
          companyId
          desc
          credit
          approved
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchClientCharges = /* GraphQL */ `
  query SearchClientCharges(
    $filter: SearchableClientChargeFilterInput
    $sort: SearchableClientChargeSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchClientCharges(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        clientId
        companyId
        orderId
        billingtransactionId
        description
        status
        stripechargeid
        stripepaymentmethod
        stripe_payment_method_details
        stripe_refund_charge
        stripe_status
        amount
        amount_refunded
        balance_transaction
        currency
        createdAt
        updatedAt
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
        billingtransaction {
          id
          orderId
          addservicetypeid
          amount
          creatorId
          clientId
          companyId
          desc
          credit
          approved
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getBillingProgress = /* GraphQL */ `
  query GetBillingProgress($companyId: String!, $dateTime: AWSDateTime!) {
    getBillingProgress(companyId: $companyId, dateTime: $dateTime) {
      companyId
      dateTime
      status
      data
      createdAt
      updatedAt
    }
  }
`;
export const listBillingProgresss = /* GraphQL */ `
  query ListBillingProgresss(
    $companyId: String
    $dateTime: ModelStringKeyConditionInput
    $filter: ModelBillingProgressFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBillingProgresss(
      companyId: $companyId
      dateTime: $dateTime
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        companyId
        dateTime
        status
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCompanyLocation = /* GraphQL */ `
  query GetCompanyLocation($id: ID!) {
    getCompanyLocation(id: $id) {
      id
      companyId
      locationname
      locationdesc
      locationpath
      addressoneline
      street
      city
      state
      country
      countrycode3166alpha2
      postalcode
      longitude
      latitude
      timezone
      phone
      virtual
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const listCompanyLocations = /* GraphQL */ `
  query ListCompanyLocations(
    $filter: ModelCompanyLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyLocations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        locationname
        locationdesc
        locationpath
        addressoneline
        street
        city
        state
        country
        countrycode3166alpha2
        postalcode
        longitude
        latitude
        timezone
        phone
        virtual
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const companyLocationByCompany = /* GraphQL */ `
  query CompanyLocationByCompany(
    $companyId: String
    $locationnameCreatedAt: ModelCompanyLocationByCompanyCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyLocationByCompany(
      companyId: $companyId
      locationnameCreatedAt: $locationnameCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        locationname
        locationdesc
        locationpath
        addressoneline
        street
        city
        state
        country
        countrycode3166alpha2
        postalcode
        longitude
        latitude
        timezone
        phone
        virtual
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const companyLocationPathByCompany = /* GraphQL */ `
  query CompanyLocationPathByCompany(
    $companyId: String
    $locationpath: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyLocationPathByCompany(
      companyId: $companyId
      locationpath: $locationpath
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        locationname
        locationdesc
        locationpath
        addressoneline
        street
        city
        state
        country
        countrycode3166alpha2
        postalcode
        longitude
        latitude
        timezone
        phone
        virtual
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchCompanyLocations = /* GraphQL */ `
  query SearchCompanyLocations(
    $filter: SearchableCompanyLocationFilterInput
    $sort: SearchableCompanyLocationSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCompanyLocations(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        companyId
        locationname
        locationdesc
        locationpath
        addressoneline
        street
        city
        state
        country
        countrycode3166alpha2
        postalcode
        longitude
        latitude
        timezone
        phone
        virtual
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getCompanyReminder = /* GraphQL */ `
  query GetCompanyReminder($id: ID!) {
    getCompanyReminder(id: $id) {
      id
      companyId
      reminderType
      reminderDays
      reminderHours
      reminderMinutes
      recipientType
      emailSubjectText
      emailHTMLFile
      smsText
      smsCallerId
      maxSendTime
      priority
      active
      deleted
      createdAt
      updatedAt
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
    }
  }
`;
export const listCompanyReminders = /* GraphQL */ `
  query ListCompanyReminders(
    $filter: ModelCompanyReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCompanyReminders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyId
        reminderType
        reminderDays
        reminderHours
        reminderMinutes
        recipientType
        emailSubjectText
        emailHTMLFile
        smsText
        smsCallerId
        maxSendTime
        priority
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchCompanyReminders = /* GraphQL */ `
  query SearchCompanyReminders(
    $filter: SearchableCompanyReminderFilterInput
    $sort: SearchableCompanyReminderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCompanyReminders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        companyId
        reminderType
        reminderDays
        reminderHours
        reminderMinutes
        recipientType
        emailSubjectText
        emailHTMLFile
        smsText
        smsCallerId
        maxSendTime
        priority
        active
        deleted
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getReminder = /* GraphQL */ `
  query GetReminder($id: ID!) {
    getReminder(id: $id) {
      id
      remSendDateTimeUTC
      remSendDate
      companyId
      reminderType
      recipientType
      sendDateTime
      status
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
      booking {
        id
        desc
        orderId
        providerId
        clientId
        startdate
        minutes
        totalAddChg
        location
        companyId
        status
        timeblockid
        cancelledAt
        noshowAt
        virtualMeetingInfo
        timezone
        TimeDisplayInfo
        orderType
        isVirtual
        manualBooking
        Notes
        MetaData
        createdAt
        updatedAt
        cancelledBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        noshowBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        bookedBy {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        rating {
          id
          providerId
          companyId
          ratingstars
          ratingtext
          ratinguserId
          active
          createdAt
          updatedAt
        }
        servicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
    }
  }
`;
export const listReminders = /* GraphQL */ `
  query ListReminders(
    $filter: ModelReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReminders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        remSendDateTimeUTC
        remSendDate
        companyId
        reminderType
        recipientType
        sendDateTime
        status
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        booking {
          id
          desc
          orderId
          providerId
          clientId
          startdate
          minutes
          totalAddChg
          location
          companyId
          status
          timeblockid
          cancelledAt
          noshowAt
          virtualMeetingInfo
          timezone
          TimeDisplayInfo
          orderType
          isVirtual
          manualBooking
          Notes
          MetaData
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const byReminderSendDate = /* GraphQL */ `
  query ByReminderSendDate(
    $remSendDate: AWSDate
    $remSendDateTimeUTCId: ModelReminderByReminderSendDateCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    byReminderSendDate(
      remSendDate: $remSendDate
      remSendDateTimeUTCId: $remSendDateTimeUTCId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        remSendDateTimeUTC
        remSendDate
        companyId
        reminderType
        recipientType
        sendDateTime
        status
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        booking {
          id
          desc
          orderId
          providerId
          clientId
          startdate
          minutes
          totalAddChg
          location
          companyId
          status
          timeblockid
          cancelledAt
          noshowAt
          virtualMeetingInfo
          timezone
          TimeDisplayInfo
          orderType
          isVirtual
          manualBooking
          Notes
          MetaData
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const remindersByCompanyStatusReminderTypeSendDate = /* GraphQL */ `
  query RemindersByCompanyStatusReminderTypeSendDate(
    $companyId: String
    $statusReminderTypeSendDateTime: ModelReminderByCompanyReminderStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelReminderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    remindersByCompanyStatusReminderTypeSendDate(
      companyId: $companyId
      statusReminderTypeSendDateTime: $statusReminderTypeSendDateTime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        remSendDateTimeUTC
        remSendDate
        companyId
        reminderType
        recipientType
        sendDateTime
        status
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        booking {
          id
          desc
          orderId
          providerId
          clientId
          startdate
          minutes
          totalAddChg
          location
          companyId
          status
          timeblockid
          cancelledAt
          noshowAt
          virtualMeetingInfo
          timezone
          TimeDisplayInfo
          orderType
          isVirtual
          manualBooking
          Notes
          MetaData
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const searchReminders = /* GraphQL */ `
  query SearchReminders(
    $filter: SearchableReminderFilterInput
    $sort: SearchableReminderSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchReminders(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        remSendDateTimeUTC
        remSendDate
        companyId
        reminderType
        recipientType
        sendDateTime
        status
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        booking {
          id
          desc
          orderId
          providerId
          clientId
          startdate
          minutes
          totalAddChg
          location
          companyId
          status
          timeblockid
          cancelledAt
          noshowAt
          virtualMeetingInfo
          timezone
          TimeDisplayInfo
          orderType
          isVirtual
          manualBooking
          Notes
          MetaData
          createdAt
          updatedAt
        }
      }
      nextToken
      total
    }
  }
`;
export const getScheduleTimeblock = /* GraphQL */ `
  query GetScheduleTimeblock($id: ID!, $scheduleinfo: String!) {
    getScheduleTimeblock(id: $id, scheduleinfo: $scheduleinfo) {
      id
      scheduleinfo
      companyId
      providerId
      locationId
      servicetypeId
      active
      internal
      slotStartStep
      instructions
      postBookingInstructions
      startDate
      sdtutc
      tz
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
      deleted
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const listScheduleTimeblocks = /* GraphQL */ `
  query ListScheduleTimeblocks(
    $id: ID
    $scheduleinfo: ModelStringKeyConditionInput
    $filter: ModelScheduleTimeblockFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listScheduleTimeblocks(
      id: $id
      scheduleinfo: $scheduleinfo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        scheduleinfo
        companyId
        providerId
        locationId
        servicetypeId
        active
        internal
        slotStartStep
        instructions
        postBookingInstructions
        startDate
        sdtutc
        tz
        endDate
        startTime
        endTime
        type
        status
        weeksToRepeat
        weekDays
        deleted
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const schedulesByLocation = /* GraphQL */ `
  query SchedulesByLocation(
    $locationId: String
    $scheduleinfo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleTimeblockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulesByLocation(
      locationId: $locationId
      scheduleinfo: $scheduleinfo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        scheduleinfo
        companyId
        providerId
        locationId
        servicetypeId
        active
        internal
        slotStartStep
        instructions
        postBookingInstructions
        startDate
        sdtutc
        tz
        endDate
        startTime
        endTime
        type
        status
        weeksToRepeat
        weekDays
        deleted
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const schedulesByCompany = /* GraphQL */ `
  query SchedulesByCompany(
    $companyId: String
    $scheduleinfo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScheduleTimeblockFilterInput
    $limit: Int
    $nextToken: String
  ) {
    schedulesByCompany(
      companyId: $companyId
      scheduleinfo: $scheduleinfo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        scheduleinfo
        companyId
        providerId
        locationId
        servicetypeId
        active
        internal
        slotStartStep
        instructions
        postBookingInstructions
        startDate
        sdtutc
        tz
        endDate
        startTime
        endTime
        type
        status
        weeksToRepeat
        weekDays
        deleted
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getProviderSchedule = /* GraphQL */ `
  query GetProviderSchedule($id: ID!, $scheduleinfo: String!) {
    getProviderSchedule(id: $id, scheduleinfo: $scheduleinfo) {
      id
      scheduleinfo
      companyId
      providerId
      locations
      services
      active
      startDate
      sdtutc
      tz
      endDate
      startTime
      endTime
      type
      status
      weeksToRepeat
      weekDays
      deleted
      rrule
      latitude
      longitude
      notes
      MetaData
      createdAt
      updatedAt
      provider {
        id
        firstname
        lc_fn
        lastname
        lc_ln
        emailaddress
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        timezone
        bio
        pictures3key
        maxtraveltype
        companyId
        numberofschedules
        offersMobileServices
        active
        deleted
        ratingstarsavg
        numberofratings
        offersVirtualServices
        virtualMeetingUserId
        vmlink
        permalink
        AcceptanceRatios
        userId
        maxTravelTime
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        locations {
          nextToken
        }
        servicetypes {
          nextToken
        }
        skills {
          nextToken
        }
      }
    }
  }
`;
export const listProviderSchedules = /* GraphQL */ `
  query ListProviderSchedules(
    $id: ID
    $scheduleinfo: ModelStringKeyConditionInput
    $filter: ModelProviderScheduleFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProviderSchedules(
      id: $id
      scheduleinfo: $scheduleinfo
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        scheduleinfo
        companyId
        providerId
        locations
        services
        active
        startDate
        sdtutc
        tz
        endDate
        startTime
        endTime
        type
        status
        weeksToRepeat
        weekDays
        deleted
        rrule
        latitude
        longitude
        notes
        MetaData
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const providerScheduleByCompany = /* GraphQL */ `
  query ProviderScheduleByCompany(
    $companyId: String
    $scheduleinfo: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProviderScheduleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    providerScheduleByCompany(
      companyId: $companyId
      scheduleinfo: $scheduleinfo
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        scheduleinfo
        companyId
        providerId
        locations
        services
        active
        startDate
        sdtutc
        tz
        endDate
        startTime
        endTime
        type
        status
        weeksToRepeat
        weekDays
        deleted
        rrule
        latitude
        longitude
        notes
        MetaData
        createdAt
        updatedAt
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getUISession = /* GraphQL */ `
  query GetUISession($id: ID!, $sessionItem: String!) {
    getUISession(id: $id, sessionItem: $sessionItem) {
      id
      sessionItem
      SessionItemData
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const listUISessions = /* GraphQL */ `
  query ListUISessions(
    $id: ID
    $sessionItem: ModelStringKeyConditionInput
    $filter: ModelUISessionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUISessions(
      id: $id
      sessionItem: $sessionItem
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sessionItem
        SessionItemData
        companyId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScheduleAvailability = /* GraphQL */ `
  query GetScheduleAvailability($schId: ID!, $statsKey: String!) {
    getScheduleAvailability(schId: $schId, statsKey: $statsKey) {
      schId
      statsKey
      availJson
      createdAt
      updatedAt
    }
  }
`;
export const listScheduleAvailabilitys = /* GraphQL */ `
  query ListScheduleAvailabilitys(
    $schId: ID
    $statsKey: ModelStringKeyConditionInput
    $filter: ModelScheduleAvailabilityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listScheduleAvailabilitys(
      schId: $schId
      statsKey: $statsKey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        schId
        statsKey
        availJson
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAdditionalCharge = /* GraphQL */ `
  query GetAdditionalCharge($id: ID!) {
    getAdditionalCharge(id: $id) {
      id
      amount
      desc
      note
      bookingId
      orderId
      companyId
      clientId
      creatorId
      createdAt
      updatedAt
      user {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      creator {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const listAdditionalCharges = /* GraphQL */ `
  query ListAdditionalCharges(
    $filter: ModelAdditionalChargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdditionalCharges(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        desc
        note
        bookingId
        orderId
        companyId
        clientId
        creatorId
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        creator {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const additionalChargesByOrderId = /* GraphQL */ `
  query AdditionalChargesByOrderId(
    $orderId: String
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdditionalChargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    additionalChargesByOrderId(
      orderId: $orderId
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        desc
        note
        bookingId
        orderId
        companyId
        clientId
        creatorId
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        creator {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const additionalChargesByCompany = /* GraphQL */ `
  query AdditionalChargesByCompany(
    $companyId: String
    $createdAtId: ModelAdditionalChargeByCompanyCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAdditionalChargeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    additionalChargesByCompany(
      companyId: $companyId
      createdAtId: $createdAtId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        amount
        desc
        note
        bookingId
        orderId
        companyId
        clientId
        creatorId
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        creator {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getRepeatApptCharge = /* GraphQL */ `
  query GetRepeatApptCharge($orderId: ID!, $bookingId: String!) {
    getRepeatApptCharge(orderId: $orderId, bookingId: $bookingId) {
      orderId
      bookingId
      companyId
      subtotal
      servicechargeamt
      taxamt
      total
      taxrate
      status
      chargeSummary
      chargeReceipt
      createdAt
      updatedAt
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const listRepeatApptCharges = /* GraphQL */ `
  query ListRepeatApptCharges(
    $orderId: ID
    $bookingId: ModelStringKeyConditionInput
    $filter: ModelRepeatApptChargeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRepeatApptCharges(
      orderId: $orderId
      bookingId: $bookingId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        orderId
        bookingId
        companyId
        subtotal
        servicechargeamt
        taxamt
        total
        taxrate
        status
        chargeSummary
        chargeReceipt
        createdAt
        updatedAt
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getBillingTransaction = /* GraphQL */ `
  query GetBillingTransaction($id: ID!) {
    getBillingTransaction(id: $id) {
      id
      orderId
      addservicetypeid
      amount
      creatorId
      clientId
      companyId
      desc
      credit
      approved
      createdAt
      updatedAt
      creator {
        id
        username
        emailaddress
        registered
        firstname
        lastname
        homephone
        workphone
        mobilephone
        phonepref
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        role
        companyId
        providerId
        active
        deleted
        contactconsent
        contactconsentdatetime
        LoginInfo
        SalesInfo
        PNInfo
        lc_name
        lc_emailaddress
        lc_ln
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
      }
      company {
        id
        name
        contactname
        emailaddress
        currency
        currencyBasedOnLocation
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        clientcanselectprovider
        active
        offersRemoteServices
        offersOnPremiseServices
        offersVirtualServices
        providerMustAcceptAppt
        useAnonymousPermalink
        multipleServices
        multipleQty
        ApptAcceptanceFlowConfig
        collectpayment
        subdomain
        tagline
        logoUrl
        billingMode
        iframeURL
        primaryColor
        addServiceFee
        serviceFeeType
        serviceFeeAmount
        taxrate
        travelTime
        bookingIntervalMinutes
        countrycode3166alpha2
        publishableStripeKey
        displayOnlyLogo
        sendratingTipMsg
        ratingTipTextMsg
        offersPackagesOnly
        forceRepeatingPackages
        offersForeverAppt
        singleApptOnly
        virtualMeetingConfig
        DashboardInfo
        BccLists
        maskeddomain
        replyemailaddress
        homepage
        clientnotesheading
        hideRatingsFromClients
        subscriptionLevel
        cancelPolicyMinsBefore
        bookingIncrement
        providerCanCancelAppt
        noProviderMsg
        displayProviderRatios
        PaymentSettings
        stripeConnectEnabled
        stripeAccount
        setupCompleted
        clientCanReschedule
        providerCanReschedule
        ProvAgnoFlowConfig
        SuggestionConfig
        createdAt
        updatedAt
      }
      addservicetype {
        id
        name
        desc
        categoryId
        categoryName
        minutes
        price
        active
        deleted
        companyId
        s3key
        imagedesc
        offeredremote
        offeredonpremise
        isBundledService
        isVisible
        includedServices
        behavior
        ordinal
        taxexempt
        TaxOverride
        locations
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        providers {
          nextToken
        }
        category {
          id
          name
          desc
          companyId
          deleted
          ordinal
          createdAt
          updatedAt
        }
        packages {
          nextToken
        }
      }
      client {
        id
        userId
        currency
        accountbalance
        stripeCustomerId
        companyId
        prevproviders {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        defaultpartialcc
        ClientInfo
        Notes
        createdAt
        updatedAt
        user {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
      }
      order {
        id
        desc
        type
        orderNo
        companyId
        clientId
        providerId
        currency
        subtotal
        servicechargeamt
        taxamt
        total
        providerPayAmt
        status
        orderSummary
        orderReceipt
        taxrate
        bookingAddress
        legaltermsAcceptedAt
        clientnotes
        Notes
        bookedByAdmin
        createdAt
        updatedAt
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        provider {
          id
          firstname
          lc_fn
          lastname
          lc_ln
          emailaddress
          phone
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          timezone
          bio
          pictures3key
          maxtraveltype
          companyId
          numberofschedules
          offersMobileServices
          active
          deleted
          ratingstarsavg
          numberofratings
          offersVirtualServices
          virtualMeetingUserId
          vmlink
          permalink
          AcceptanceRatios
          userId
          maxTravelTime
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        clientpackage {
          id
          companyId
          clientId
          userId
          servicetypeId
          packageId
          initialQuantity
          usedQuantity
          status
          active
          createdAt
          updatedAt
        }
        bookings {
          nextToken
        }
      }
    }
  }
`;
export const listBillingTransactions = /* GraphQL */ `
  query ListBillingTransactions(
    $filter: ModelBillingTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBillingTransactions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orderId
        addservicetypeid
        amount
        creatorId
        clientId
        companyId
        desc
        credit
        approved
        createdAt
        updatedAt
        creator {
          id
          username
          emailaddress
          registered
          firstname
          lastname
          homephone
          workphone
          mobilephone
          phonepref
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          role
          companyId
          providerId
          active
          deleted
          contactconsent
          contactconsentdatetime
          LoginInfo
          SalesInfo
          PNInfo
          lc_name
          lc_emailaddress
          lc_ln
          createdAt
          updatedAt
        }
        company {
          id
          name
          contactname
          emailaddress
          currency
          currencyBasedOnLocation
          addressoneline
          street
          city
          state
          country
          postalcode
          longitude
          latitude
          clientcanselectprovider
          active
          offersRemoteServices
          offersOnPremiseServices
          offersVirtualServices
          providerMustAcceptAppt
          useAnonymousPermalink
          multipleServices
          multipleQty
          ApptAcceptanceFlowConfig
          collectpayment
          subdomain
          tagline
          logoUrl
          billingMode
          iframeURL
          primaryColor
          addServiceFee
          serviceFeeType
          serviceFeeAmount
          taxrate
          travelTime
          bookingIntervalMinutes
          countrycode3166alpha2
          publishableStripeKey
          displayOnlyLogo
          sendratingTipMsg
          ratingTipTextMsg
          offersPackagesOnly
          forceRepeatingPackages
          offersForeverAppt
          singleApptOnly
          virtualMeetingConfig
          DashboardInfo
          BccLists
          maskeddomain
          replyemailaddress
          homepage
          clientnotesheading
          hideRatingsFromClients
          subscriptionLevel
          cancelPolicyMinsBefore
          bookingIncrement
          providerCanCancelAppt
          noProviderMsg
          displayProviderRatios
          PaymentSettings
          stripeConnectEnabled
          stripeAccount
          setupCompleted
          clientCanReschedule
          providerCanReschedule
          ProvAgnoFlowConfig
          SuggestionConfig
          createdAt
          updatedAt
        }
        addservicetype {
          id
          name
          desc
          categoryId
          categoryName
          minutes
          price
          active
          deleted
          companyId
          s3key
          imagedesc
          offeredremote
          offeredonpremise
          isBundledService
          isVisible
          includedServices
          behavior
          ordinal
          taxexempt
          TaxOverride
          locations
          createdAt
          updatedAt
        }
        client {
          id
          userId
          currency
          accountbalance
          stripeCustomerId
          companyId
          defaultpartialcc
          ClientInfo
          Notes
          createdAt
          updatedAt
        }
        order {
          id
          desc
          type
          orderNo
          companyId
          clientId
          providerId
          currency
          subtotal
          servicechargeamt
          taxamt
          total
          providerPayAmt
          status
          orderSummary
          orderReceipt
          taxrate
          bookingAddress
          legaltermsAcceptedAt
          clientnotes
          Notes
          bookedByAdmin
          createdAt
          updatedAt
        }
      }
      nextToken
    }
  }
`;
export const getTaxRates = /* GraphQL */ `
  query GetTaxRates($id: ID!) {
    getTaxRates(id: $id) {
      id
      countrycode
      provstatecode
      rate
      createdAt
      updatedAt
    }
  }
`;
export const listTaxRatess = /* GraphQL */ `
  query ListTaxRatess(
    $filter: ModelTaxRatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaxRatess(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        countrycode
        provstatecode
        rate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRepeatApptCreation = /* GraphQL */ `
  query GetRepeatApptCreation($id: ID!, $datakey: String!) {
    getRepeatApptCreation(id: $id, datakey: $datakey) {
      id
      datakey
      repeatCount
      repeatUnit
      numOfApptsCreated
      active
      processedStatus
      currentEndDate
      originalStartDate
      originalEndDate
      extented
      createdAt
      updatedAt
    }
  }
`;
export const listRepeatApptCreations = /* GraphQL */ `
  query ListRepeatApptCreations(
    $id: ID
    $datakey: ModelStringKeyConditionInput
    $filter: ModelRepeatApptCreationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRepeatApptCreations(
      id: $id
      datakey: $datakey
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        datakey
        repeatCount
        repeatUnit
        numOfApptsCreated
        active
        processedStatus
        currentEndDate
        originalStartDate
        originalEndDate
        extented
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEnvt = /* GraphQL */ `
  query GetEnvt($id: ID!) {
    getEnvt(id: $id) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export const listEnvts = /* GraphQL */ `
  query ListEnvts(
    $filter: ModelEnvtFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEnvts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBookingRequest = /* GraphQL */ `
  query GetBookingRequest($id: ID!, $recordType: String!) {
    getBookingRequest(id: $id, recordType: $recordType) {
      id
      recordType
      data
      providerId
      accepted
      acceptedBy
      status
      createdAt
      updatedAt
    }
  }
`;
export const listBookingRequests = /* GraphQL */ `
  query ListBookingRequests(
    $id: ID
    $recordType: ModelStringKeyConditionInput
    $filter: ModelBookingRequestFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listBookingRequests(
      id: $id
      recordType: $recordType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        recordType
        data
        providerId
        accepted
        acceptedBy
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRequestMessages = /* GraphQL */ `
  query GetRequestMessages($id: ID!, $recordType: String!) {
    getRequestMessages(id: $id, recordType: $recordType) {
      id
      recordType
      bookingRequestId
      providerId
      data
      msg
      seqNum
      status
      createdAt
      updatedAt
    }
  }
`;
export const listRequestMessagess = /* GraphQL */ `
  query ListRequestMessagess(
    $id: ID
    $recordType: ModelStringKeyConditionInput
    $filter: ModelRequestMessagesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRequestMessagess(
      id: $id
      recordType: $recordType
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        recordType
        bookingRequestId
        providerId
        data
        msg
        seqNum
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSystemErrorLog = /* GraphQL */ `
  query GetSystemErrorLog($companyId: String!, $createdAt: AWSDateTime!) {
    getSystemErrorLog(companyId: $companyId, createdAt: $createdAt) {
      companyId
      createdAt
      severity
      source
      line
      error
      updatedAt
    }
  }
`;
export const listSystemErrorLogs = /* GraphQL */ `
  query ListSystemErrorLogs(
    $companyId: String
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelSystemErrorLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSystemErrorLogs(
      companyId: $companyId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        companyId
        createdAt
        severity
        source
        line
        error
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPublicAccessToken = /* GraphQL */ `
  query GetPublicAccessToken($id: ID!) {
    getPublicAccessToken(id: $id) {
      id
      role
      expiryDate
      createdAt
      updatedAt
    }
  }
`;
export const listPublicAccessTokens = /* GraphQL */ `
  query ListPublicAccessTokens(
    $filter: ModelPublicAccessTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicAccessTokens(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        role
        expiryDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRefData = /* GraphQL */ `
  query GetRefData($refType: ID!, $refName: String!) {
    getRefData(refType: $refType, refName: $refName) {
      refType
      refName
      refValue
      refInt
      overrideValue
      createdAt
      updatedAt
    }
  }
`;
export const listRefDatas = /* GraphQL */ `
  query ListRefDatas(
    $refType: ID
    $refName: ModelStringKeyConditionInput
    $filter: ModelRefDataFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRefDatas(
      refType: $refType
      refName: $refName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        refType
        refName
        refValue
        refInt
        overrideValue
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStatistic = /* GraphQL */ `
  query GetStatistic($id: ID!, $dataPoint: String!) {
    getStatistic(id: $id, dataPoint: $dataPoint) {
      id
      dataPoint
      amount
      count
      createdAt
      updatedAt
    }
  }
`;
export const listStatistics = /* GraphQL */ `
  query ListStatistics(
    $id: ID
    $dataPoint: ModelStringKeyConditionInput
    $filter: ModelStatisticFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listStatistics(
      id: $id
      dataPoint: $dataPoint
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        dataPoint
        amount
        count
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProspect = /* GraphQL */ `
  query GetProspect($companyId: ID!, $prospectId: String!) {
    getProspect(companyId: $companyId, prospectId: $prospectId) {
      companyId
      prospectId
      emailaddress
      firstname
      lastname
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      longitude
      latitude
      pageUrl
      message
      SearchParamsInfo
      createdAt
      updatedAt
    }
  }
`;
export const listProspects = /* GraphQL */ `
  query ListProspects(
    $companyId: ID
    $prospectId: ModelStringKeyConditionInput
    $filter: ModelProspectFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listProspects(
      companyId: $companyId
      prospectId: $prospectId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        companyId
        prospectId
        emailaddress
        firstname
        lastname
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        pageUrl
        message
        SearchParamsInfo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchProspects = /* GraphQL */ `
  query SearchProspects(
    $filter: SearchableProspectFilterInput
    $sort: SearchableProspectSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchProspects(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        companyId
        prospectId
        emailaddress
        firstname
        lastname
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        longitude
        latitude
        pageUrl
        message
        SearchParamsInfo
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getManualBooking = /* GraphQL */ `
  query GetManualBooking($companyId: ID!, $recordId: String!) {
    getManualBooking(companyId: $companyId, recordId: $recordId) {
      companyId
      recordId
      emailaddress
      firstname
      lastname
      phone
      addressoneline
      street
      city
      state
      country
      postalcode
      message
      MetadataInfo
      createdAt
      updatedAt
    }
  }
`;
export const listManualBookings = /* GraphQL */ `
  query ListManualBookings(
    $companyId: ID
    $recordId: ModelStringKeyConditionInput
    $filter: ModelManualBookingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listManualBookings(
      companyId: $companyId
      recordId: $recordId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        companyId
        recordId
        emailaddress
        firstname
        lastname
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        message
        MetadataInfo
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchManualBookings = /* GraphQL */ `
  query SearchManualBookings(
    $filter: SearchableManualBookingFilterInput
    $sort: SearchableManualBookingSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchManualBookings(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        companyId
        recordId
        emailaddress
        firstname
        lastname
        phone
        addressoneline
        street
        city
        state
        country
        postalcode
        message
        MetadataInfo
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
export const getAudit = /* GraphQL */ `
  query GetAudit($companyIdUserId: String!, $datetime: AWSDateTime!) {
    getAudit(companyIdUserId: $companyIdUserId, datetime: $datetime) {
      companyIdUserId
      datetime
      compResId
      resType
      companyId
      userFN
      userLN
      userEmail
      userRole
      userAction
      data
      createdAt
      updatedAt
    }
  }
`;
export const listAudits = /* GraphQL */ `
  query ListAudits(
    $companyIdUserId: String
    $datetime: ModelStringKeyConditionInput
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAudits(
      companyIdUserId: $companyIdUserId
      datetime: $datetime
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        companyIdUserId
        datetime
        compResId
        resType
        companyId
        userFN
        userLN
        userEmail
        userRole
        userAction
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const auditByResource = /* GraphQL */ `
  query AuditByResource(
    $compResId: String
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    auditByResource(
      compResId: $compResId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        companyIdUserId
        datetime
        compResId
        resType
        companyId
        userFN
        userLN
        userEmail
        userRole
        userAction
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const auditByCompany = /* GraphQL */ `
  query AuditByCompany(
    $companyId: String
    $datetime: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAuditFilterInput
    $limit: Int
    $nextToken: String
  ) {
    auditByCompany(
      companyId: $companyId
      datetime: $datetime
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        companyIdUserId
        datetime
        compResId
        resType
        companyId
        userFN
        userLN
        userEmail
        userRole
        userAction
        data
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getIntegrationToken = /* GraphQL */ `
  query GetIntegrationToken(
    $companyIdProviderId: String!
    $serviceName: String!
  ) {
    getIntegrationToken(
      companyIdProviderId: $companyIdProviderId
      serviceName: $serviceName
    ) {
      companyIdProviderId
      serviceName
      TokenInfo
      companyId
      createdAt
      updatedAt
    }
  }
`;
export const listIntegrationTokens = /* GraphQL */ `
  query ListIntegrationTokens(
    $companyIdProviderId: String
    $serviceName: ModelStringKeyConditionInput
    $filter: ModelIntegrationTokenFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listIntegrationTokens(
      companyIdProviderId: $companyIdProviderId
      serviceName: $serviceName
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        companyIdProviderId
        serviceName
        TokenInfo
        companyId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const integrationTokensByCompany = /* GraphQL */ `
  query IntegrationTokensByCompany(
    $companyId: String
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelIntegrationTokenFilterInput
    $limit: Int
    $nextToken: String
  ) {
    integrationTokensByCompany(
      companyId: $companyId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        companyIdProviderId
        serviceName
        TokenInfo
        companyId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
